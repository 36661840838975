/* eslint-disable */
Webflow.require("ix2").init({
	events: {
		e: {
			id: "e",
			name: "",
			animationType: "custom",
			eventTypeId: "PAGE_START",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-33",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x17ed8c9537b,
		},
		"e-4": {
			id: "e-4",
			name: "",
			animationType: "custom",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-3",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-227",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|dfc98ce4-4057-dfcb-ca06-5666833d81be",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|dfc98ce4-4057-dfcb-ca06-5666833d81be",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 30,
				scrollOffsetUnit: "%",
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x17ed94ec3f7,
		},
		"e-8": {
			id: "e-8",
			name: "",
			animationType: "custom",
			eventTypeId: "SCROLLING_IN_VIEW",
			action: {
				id: "",
				actionTypeId: "GENERAL_CONTINUOUS_ACTION",
				config: { actionListId: "a-5", affectedElements: {}, duration: 0 },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|d7fb73b1-0752-1e7b-a7eb-1aee0902e269",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|d7fb73b1-0752-1e7b-a7eb-1aee0902e269",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: [
				{
					continuousParameterGroupId: "a-5-p",
					smoothing: 95,
					startsEntering: !0,
					addStartOffset: !1,
					addOffsetValue: 50,
					startsExiting: !1,
					addEndOffset: !1,
					endOffsetValue: 50,
				},
			],
			createdOn: 0x17ed964ca31,
		},
		"e-18": {
			id: "e-18",
			name: "",
			animationType: "preset",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "SLIDE_EFFECT",
				instant: !1,
				config: { actionListId: "slideInBottom", autoStopEventId: "e-58" },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|71d5b42b-8bf5-f284-d483-668ff389f0e1",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|71d5b42b-8bf5-f284-d483-668ff389f0e1",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 20,
				scrollOffsetUnit: "%",
				delay: 0,
				direction: "BOTTOM",
				effectIn: !0,
			},
			createdOn: 0x17eda53e077,
		},
		"e-26": {
			id: "e-26",
			name: "",
			animationType: "custom",
			eventTypeId: "SCROLLING_IN_VIEW",
			action: {
				id: "",
				actionTypeId: "GENERAL_CONTINUOUS_ACTION",
				config: { actionListId: "a-2", affectedElements: {}, duration: 0 },
			},
			mediaQueries: ["main"],
			target: {
				id: "673ee4cd088aa593ca5ba409|d0dc75fc-378f-fae6-2fa6-b805bb6f601e",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|d0dc75fc-378f-fae6-2fa6-b805bb6f601e",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: [
				{
					continuousParameterGroupId: "a-2-p",
					smoothing: 95,
					startsEntering: !0,
					addStartOffset: !1,
					addOffsetValue: 50,
					startsExiting: !1,
					addEndOffset: !1,
					endOffsetValue: 50,
				},
			],
			createdOn: 0x17edf0d8790,
		},
		"e-59": {
			id: "e-59",
			name: "",
			animationType: "custom",
			eventTypeId: "SCROLLING_IN_VIEW",
			action: {
				id: "",
				actionTypeId: "GENERAL_CONTINUOUS_ACTION",
				config: { actionListId: "a-22", affectedElements: {}, duration: 0 },
			},
			mediaQueries: ["main"],
			target: {
				id: "673ee4cd088aa593ca5ba409|97a7dad8-e368-7761-6daa-912063dc1865",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|97a7dad8-e368-7761-6daa-912063dc1865",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: [
				{
					continuousParameterGroupId: "a-22-p",
					smoothing: 90,
					startsEntering: !1,
					addStartOffset: !1,
					addOffsetValue: 50,
					startsExiting: !1,
					addEndOffset: !0,
					endOffsetValue: 30,
				},
			],
			createdOn: 0x17fa74a0656,
		},
		"e-63": {
			id: "e-63",
			name: "",
			animationType: "custom",
			eventTypeId: "SCROLLING_IN_VIEW",
			action: {
				id: "",
				actionTypeId: "GENERAL_CONTINUOUS_ACTION",
				config: { actionListId: "a-23", affectedElements: {}, duration: 0 },
			},
			mediaQueries: ["medium"],
			target: {
				id: "673ee4cd088aa593ca5ba409|97a7dad8-e368-7761-6daa-912063dc1865",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|97a7dad8-e368-7761-6daa-912063dc1865",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: [
				{
					continuousParameterGroupId: "a-23-p",
					smoothing: 95,
					startsEntering: !0,
					addStartOffset: !0,
					addOffsetValue: 20,
					startsExiting: !1,
					addEndOffset: !0,
					endOffsetValue: 20,
				},
			],
			createdOn: 0x17fac210e65,
		},
		"e-76": {
			id: "e-76",
			name: "",
			animationType: "custom",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-28",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-298",
				},
			},
			mediaQueries: ["main"],
			target: {
				selector: ".fixed-footer-visibility-block",
				originalId:
					"673ee4cd088aa593ca5ba409|7c160326-7512-97dc-3008-def8a1417e94",
				appliesTo: "CLASS",
			},
			targets: [
				{
					selector: ".fixed-footer-visibility-block",
					originalId:
						"673ee4cd088aa593ca5ba409|7c160326-7512-97dc-3008-def8a1417e94",
					appliesTo: "CLASS",
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 45,
				scrollOffsetUnit: "%",
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18c3bc8ae2d,
		},
		"e-77": {
			id: "e-77",
			name: "",
			animationType: "custom",
			eventTypeId: "SCROLL_OUT_OF_VIEW",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-29",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-76",
				},
			},
			mediaQueries: ["main"],
			target: {
				selector: ".fixed-footer-visibility-block",
				originalId:
					"673ee4cd088aa593ca5ba409|7c160326-7512-97dc-3008-def8a1417e94",
				appliesTo: "CLASS",
			},
			targets: [
				{
					selector: ".fixed-footer-visibility-block",
					originalId:
						"673ee4cd088aa593ca5ba409|7c160326-7512-97dc-3008-def8a1417e94",
					appliesTo: "CLASS",
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 45,
				scrollOffsetUnit: "%",
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18c3bc8ae2e,
		},
		"e-80": {
			id: "e-80",
			name: "",
			animationType: "preset",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "SLIDE_EFFECT",
				instant: !1,
				config: { actionListId: "slideInBottom", autoStopEventId: "e-240" },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|fb27b07e-a3cd-c757-f592-88b9856180ec",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|fb27b07e-a3cd-c757-f592-88b9856180ec",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: 0,
				direction: "BOTTOM",
				effectIn: !0,
			},
			createdOn: 0x18ec8833d4f,
		},


		"e-123": {
			id: "e-123",
			name: "",
			animationType: "custom",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-36",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-124",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|054d9b51-e7c6-0f28-cb92-fa3e248c1043",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|054d9b51-e7c6-0f28-cb92-fa3e248c1043",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x17d36e3fbb0,
		},
		"e-129": {
			id: "e-129",
			name: "",
			animationType: "custom",
			eventTypeId: "SCROLLING_IN_VIEW",
			action: {
				id: "",
				actionTypeId: "GENERAL_CONTINUOUS_ACTION",
				config: { actionListId: "a-35", affectedElements: {}, duration: 0 },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|54552148-4f55-5cd1-f746-e60902228f89",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|54552148-4f55-5cd1-f746-e60902228f89",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: [
				{
					continuousParameterGroupId: "a-35-p",
					smoothing: 50,
					startsEntering: !0,
					addStartOffset: !1,
					addOffsetValue: 50,
					startsExiting: !1,
					addEndOffset: !1,
					endOffsetValue: 50,
				},
			],
			createdOn: 0x18ed2c22aa9,
		},
		"e-132": {
			id: "e-132",
			name: "",
			animationType: "custom",
			eventTypeId: "MOUSE_OVER",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-41",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-133",
				},
			},
			mediaQueries: ["main"],
			target: {
				selector: ".button-link",
				originalId:
					"66210bc8d9782689a2ac166b|0010f4b9-4303-d6be-9dad-9c5ea8ed336e",
				appliesTo: "CLASS",
			},
			targets: [
				{
					selector: ".button-link",
					originalId:
						"66210bc8d9782689a2ac166b|0010f4b9-4303-d6be-9dad-9c5ea8ed336e",
					appliesTo: "CLASS",
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x17de0249dae,
		},
		"e-133": {
			id: "e-133",
			name: "",
			animationType: "custom",
			eventTypeId: "MOUSE_OUT",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-42",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-132",
				},
			},
			mediaQueries: ["main"],
			target: {
				selector: ".button-link",
				originalId:
					"66210bc8d9782689a2ac166b|0010f4b9-4303-d6be-9dad-9c5ea8ed336e",
				appliesTo: "CLASS",
			},
			targets: [
				{
					selector: ".button-link",
					originalId:
						"66210bc8d9782689a2ac166b|0010f4b9-4303-d6be-9dad-9c5ea8ed336e",
					appliesTo: "CLASS",
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x17de0249db0,
		},
		"e-135": {
			id: "e-135",
			name: "",
			animationType: "custom",
			eventTypeId: "PAGE_SCROLL",
			action: {
				id: "",
				actionTypeId: "GENERAL_CONTINUOUS_ACTION",
				config: { actionListId: "a-45", affectedElements: {}, duration: 0 },
			},
			mediaQueries: ["main"],
			target: {
				id: "673ee4cd088aa593ca5ba409",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: [
				{
					continuousParameterGroupId: "a-45-p",
					smoothing: 50,
					startsEntering: !0,
					addStartOffset: !1,
					addOffsetValue: 50,
					startsExiting: !1,
					addEndOffset: !1,
					endOffsetValue: 50,
				},
			],
			createdOn: 0x18ef1d981de,
		},
		"e-138": {
			id: "e-138",
			name: "",
			animationType: "preset",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "SLIDE_EFFECT",
				instant: !1,
				config: { actionListId: "slideInBottom", autoStopEventId: "e-139" },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "7b6d945a-0109-72f8-feba-d41885a2ac55",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "7b6d945a-0109-72f8-feba-d41885a2ac55",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: 0,
				direction: "BOTTOM",
				effectIn: !0,
			},
			createdOn: 0x18ef2662555,
		},
		"e-141": {
			id: "e-141",
			name: "",
			animationType: "custom",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-49",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-142",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|5196115c-93f8-a340-3e9e-93c9ef19581a",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|5196115c-93f8-a340-3e9e-93c9ef19581a",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18ef36bcad4,
		},
		"e-155": {
			id: "e-155",
			name: "",
			animationType: "custom",
			eventTypeId: "PAGE_START",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-56",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-156",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18ef3b58756,
		},
		"e-159": {
			id: "e-159",
			name: "",
			animationType: "custom",
			eventTypeId: "PAGE_START",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-57",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-160",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18ef4f40ffd,
		},
		"e-163": {
			id: "e-163",
			name: "",
			animationType: "custom",
			eventTypeId: "MOUSE_MOVE",
			action: {
				id: "",
				actionTypeId: "GENERAL_CONTINUOUS_ACTION",
				config: { actionListId: "a-59", affectedElements: {}, duration: 0 },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				selector: ".button",
				originalId:
					"65ddcd57bb3ff7e58402846c|f1524d88-a018-8afd-1b6d-6cea78405b30",
				appliesTo: "CLASS",
			},
			targets: [
				{
					selector: ".button",
					originalId:
						"65ddcd57bb3ff7e58402846c|f1524d88-a018-8afd-1b6d-6cea78405b30",
					appliesTo: "CLASS",
				},
			],
			config: [
				{
					continuousParameterGroupId: "a-59-p",
					selectedAxis: "X_AXIS",
					basedOn: "ELEMENT",
					reverse: !1,
					smoothing: 85,
					restingState: 50,
				},
				{
					continuousParameterGroupId: "a-59-p-2",
					selectedAxis: "Y_AXIS",
					basedOn: "ELEMENT",
					reverse: !1,
					smoothing: 85,
					restingState: 50,
				},
			],
			createdOn: 0x1788f4ef8b0,
		},
		"e-228": {
			id: "e-228",
			animationType: "custom",
			eventTypeId: "DROPDOWN_OPEN",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-73",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-14",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				selector: ".dropdown",
				originalId: "2a6ed7ea-8a4f-b991-340a-93d257e176e0",
				appliesTo: "CLASS",
			},
			targets: [
				{
					selector: ".dropdown",
					originalId: "2a6ed7ea-8a4f-b991-340a-93d257e176e0",
					appliesTo: "CLASS",
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x16f209bf8a5,
		},
		"e-229": {
			id: "e-229",
			animationType: "custom",
			eventTypeId: "DROPDOWN_CLOSE",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-74",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-13",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				selector: ".dropdown",
				originalId: "2a6ed7ea-8a4f-b991-340a-93d257e176e0",
				appliesTo: "CLASS",
			},
			targets: [
				{
					selector: ".dropdown",
					originalId: "2a6ed7ea-8a4f-b991-340a-93d257e176e0",
					appliesTo: "CLASS",
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x16f209bf8a5,
		},
		"e-240": {
			id: "e-240",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_CLICK",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-77",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-241",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "49074ee8-d6e9-617e-8005-9df85d8ca65c",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "49074ee8-d6e9-617e-8005-9df85d8ca65c",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18ee60ec308,
		},
		"e-241": {
			id: "e-241",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_SECOND_CLICK",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-78",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-240",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "49074ee8-d6e9-617e-8005-9df85d8ca65c",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "49074ee8-d6e9-617e-8005-9df85d8ca65c",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18ee60ec308,
		},
		"e-242": {
			id: "e-242",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_CLICK",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-79",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-243",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|ececbbdc-893c-6c82-a7bd-9c98b1e74140",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|ececbbdc-893c-6c82-a7bd-9c98b1e74140",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x1894f97b4c9,
		},
		"e-244": {
			id: "e-244",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_CLICK",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-80",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-245",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|ececbbdc-893c-6c82-a7bd-9c98b1e74142",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|ececbbdc-893c-6c82-a7bd-9c98b1e74142",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x1894f97b4c9,
		},

		"e-250": {
			id: "e-250",
			name: "",
			animationType: "custom",
			eventTypeId: "MOUSE_OUT",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-84",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-353",
				},
			},
			mediaQueries: ["main"],
			target: {
				selector: ".card-item",
				originalId:
					"673ee4cd088aa593ca5ba409|e08f249b-85ca-367f-ffee-88e61ac23b06",
				appliesTo: "CLASS",
			},
			targets: [
				{
					selector: ".card-item",
					originalId:
						"673ee4cd088aa593ca5ba409|e08f249b-85ca-367f-ffee-88e61ac23b06",
					appliesTo: "CLASS",
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x17f9dd75c18,
		},
		"e-253": {
			id: "e-253",
			name: "",
			animationType: "custom",
			eventTypeId: "MOUSE_OVER",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-83",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-347",
				},
			},
			mediaQueries: ["main"],
			target: {
				selector: ".card-item",
				originalId:
					"673ee4cd088aa593ca5ba409|e08f249b-85ca-367f-ffee-88e61ac23b06",
				appliesTo: "CLASS",
			},
			targets: [
				{
					selector: ".card-item",
					originalId:
						"673ee4cd088aa593ca5ba409|e08f249b-85ca-367f-ffee-88e61ac23b06",
					appliesTo: "CLASS",
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x17f9dd75c15,
		},
		"e-262": {
			id: "e-262",
			name: "",
			animationType: "preset",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "SLIDE_EFFECT",
				instant: !1,
				config: { actionListId: "slideInBottom", autoStopEventId: "e-263" },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|ece9f6ca-dc14-050f-e933-f54024f03912",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|ece9f6ca-dc14-050f-e933-f54024f03912",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: 0,
				direction: "BOTTOM",
				effectIn: !0,
			},
			createdOn: 0x18f387fdfbd,
		},
		"e-264": {
			id: "e-264",
			name: "",
			animationType: "custom",
			eventTypeId: "MOUSE_OVER",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-85",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-265",
				},
			},
			mediaQueries: ["main"],
			target: {
				id: "673ee4cd088aa593ca5ba409|a837d3d1-4b26-59e6-5eb6-4572db3a3fea",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|a837d3d1-4b26-59e6-5eb6-4572db3a3fea",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18f39cd8242,
		},
		"e-265": {
			id: "e-265",
			name: "",
			animationType: "custom",
			eventTypeId: "MOUSE_OUT",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-86",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-264",
				},
			},
			mediaQueries: ["main"],
			target: {
				id: "673ee4cd088aa593ca5ba409|a837d3d1-4b26-59e6-5eb6-4572db3a3fea",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|a837d3d1-4b26-59e6-5eb6-4572db3a3fea",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18f39cd8243,
		},
		"e-273": {
			id: "e-273",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OVER",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-91",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-277",
				},
			},
			mediaQueries: ["main"],
			target: {
				id: "673ee4cd088aa593ca5ba409|6cb572f2-5062-02d2-b42b-6b2048143e55",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|6cb572f2-5062-02d2-b42b-6b2048143e55",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18f15ddd05d,
		},
		"e-274": {
			id: "e-274",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OVER",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-87",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-279",
				},
			},
			mediaQueries: ["main"],
			target: {
				id: "673ee4cd088aa593ca5ba409|6cb572f2-5062-02d2-b42b-6b2048143e0c",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|6cb572f2-5062-02d2-b42b-6b2048143e0c",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18f15ddd05d,
		},
		"e-275": {
			id: "e-275",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OUT",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-88",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-283",
				},
			},
			mediaQueries: ["main"],
			target: {
				id: "673ee4cd088aa593ca5ba409|6cb572f2-5062-02d2-b42b-6b2048143df4",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|6cb572f2-5062-02d2-b42b-6b2048143df4",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18f15ddd05d,
		},
		"e-277": {
			id: "e-277",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OUT",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-92",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-273",
				},
			},
			mediaQueries: ["main"],
			target: {
				id: "673ee4cd088aa593ca5ba409|6cb572f2-5062-02d2-b42b-6b2048143e55",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|6cb572f2-5062-02d2-b42b-6b2048143e55",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18f15ddd05d,
		},
		"e-278": {
			id: "e-278",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OUT",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-88",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-280",
				},
			},
			mediaQueries: ["main"],
			target: {
				id: "673ee4cd088aa593ca5ba409|6cb572f2-5062-02d2-b42b-6b2048143e24",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|6cb572f2-5062-02d2-b42b-6b2048143e24",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18f15ddd05d,
		},
		"e-279": {
			id: "e-279",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OUT",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-88",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-274",
				},
			},
			mediaQueries: ["main"],
			target: {
				id: "673ee4cd088aa593ca5ba409|6cb572f2-5062-02d2-b42b-6b2048143e0c",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|6cb572f2-5062-02d2-b42b-6b2048143e0c",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18f15ddd05d,
		},
		"e-280": {
			id: "e-280",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OVER",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-87",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-278",
				},
			},
			mediaQueries: ["main"],
			target: {
				id: "673ee4cd088aa593ca5ba409|6cb572f2-5062-02d2-b42b-6b2048143e24",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|6cb572f2-5062-02d2-b42b-6b2048143e24",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18f15ddd05d,
		},
		"e-282": {
			id: "e-282",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OUT",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-88",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-284",
				},
			},
			mediaQueries: ["main"],
			target: {
				id: "673ee4cd088aa593ca5ba409|6cb572f2-5062-02d2-b42b-6b2048143e3c",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|6cb572f2-5062-02d2-b42b-6b2048143e3c",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18f15ddd05d,
		},
		"e-283": {
			id: "e-283",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OVER",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-87",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-275",
				},
			},
			mediaQueries: ["main"],
			target: {
				id: "673ee4cd088aa593ca5ba409|6cb572f2-5062-02d2-b42b-6b2048143df4",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|6cb572f2-5062-02d2-b42b-6b2048143df4",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18f15ddd05d,
		},
		"e-284": {
			id: "e-284",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OVER",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-87",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-282",
				},
			},
			mediaQueries: ["main"],
			target: {
				id: "673ee4cd088aa593ca5ba409|6cb572f2-5062-02d2-b42b-6b2048143e3c",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|6cb572f2-5062-02d2-b42b-6b2048143e3c",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18f15ddd05d,
		},
		"e-290": {
			id: "e-290",
			name: "",
			animationType: "preset",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "SLIDE_EFFECT",
				instant: !1,
				config: { actionListId: "slideInTop", autoStopEventId: "e-291" },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "49074ee8-d6e9-617e-8005-9df85d8ca5d6",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "49074ee8-d6e9-617e-8005-9df85d8ca5d6",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: 150,
				direction: "TOP",
				effectIn: !0,
			},
			createdOn: 0x18f3aa915de,
		},
		"e-292": {
			id: "e-292",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_CLICK",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-93",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-293",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "49074ee8-d6e9-617e-8005-9df85d8ca5da",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "49074ee8-d6e9-617e-8005-9df85d8ca5da",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18f3aa915de,
		},
		"e-294": {
			id: "e-294",
			name: "",
			animationType: "custom",
			eventTypeId: "SCROLLING_IN_VIEW",
			action: {
				id: "",
				actionTypeId: "GENERAL_CONTINUOUS_ACTION",
				config: { actionListId: "a-94", affectedElements: {}, duration: 0 },
			},
			mediaQueries: ["main"],
			target: {
				id: "673ee4cd088aa593ca5ba409|c5f658dd-5043-c45f-97b7-01f583c558c8",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|c5f658dd-5043-c45f-97b7-01f583c558c8",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: [
				{
					continuousParameterGroupId: "a-94-p",
					smoothing: 83,
					startsEntering: !0,
					addStartOffset: !1,
					addOffsetValue: 50,
					startsExiting: !1,
					addEndOffset: !1,
					endOffsetValue: 50,
				},
			],
			createdOn: 0x18e51fe9e66,
		},
		"e-295": {
			id: "e-295",
			name: "",
			animationType: "preset",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "FADE_EFFECT",
				instant: !1,
				config: { actionListId: "fadeIn", autoStopEventId: "e-296" },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|ad66da3e-9a53-e1d0-2cc8-33d7fc585894",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|ad66da3e-9a53-e1d0-2cc8-33d7fc585894",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: 2100,
				direction: null,
				effectIn: !0,
			},
			createdOn: 0x18f9b1befee,
		},
		"e-297": {
			id: "e-297",
			name: "",
			animationType: "custom",
			eventTypeId: "MOUSE_MOVE",
			action: {
				id: "",
				actionTypeId: "GENERAL_CONTINUOUS_ACTION",
				config: { actionListId: "a-7", affectedElements: {}, duration: 0 },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|9a8c8c5e-18d4-aeb9-bc37-bac71fe0745b",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|9a8c8c5e-18d4-aeb9-bc37-bac71fe0745b",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: [
				{
					continuousParameterGroupId: "a-7-p",
					selectedAxis: "X_AXIS",
					basedOn: "ELEMENT",
					reverse: !1,
					smoothing: 50,
					restingState: 50,
				},
				{
					continuousParameterGroupId: "a-7-p-2",
					selectedAxis: "Y_AXIS",
					basedOn: "ELEMENT",
					reverse: !1,
					smoothing: 50,
					restingState: 50,
				},
			],
			createdOn: 0x18f9b2889b2,
		},
		"e-298": {
			id: "e-298",
			name: "",
			animationType: "custom",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-95",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-299",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|0c295aa1-adf5-f696-cd2b-f977ad44ad31",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|0c295aa1-adf5-f696-cd2b-f977ad44ad31",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18f9b3e37b2,
		},
		"e-302": {
			id: "e-302",
			name: "",
			animationType: "preset",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "GROW_EFFECT",
				instant: !1,
				config: { actionListId: "growIn", autoStopEventId: "e-303" },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|ad66da3e-9a53-e1d0-2cc8-33d7fc585892",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|ad66da3e-9a53-e1d0-2cc8-33d7fc585892",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: 800,
				direction: null,
				effectIn: !0,
			},
			createdOn: 0x18f9b73e8e9,
		},
		"e-304": {
			id: "e-304",
			name: "",
			animationType: "preset",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "SLIDE_EFFECT",
				instant: !1,
				config: { actionListId: "slideInBottom", autoStopEventId: "e-305" },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|08d34a3d-37b0-0630-8f79-45fa12ed9540",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|08d34a3d-37b0-0630-8f79-45fa12ed9540",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: 0,
				direction: "BOTTOM",
				effectIn: !0,
			},
			createdOn: 0x18f9eec35cb,
		},
		"e-306": {
			id: "e-306",
			name: "",
			animationType: "preset",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "SLIDE_EFFECT",
				instant: !1,
				config: { actionListId: "slideInBottom", autoStopEventId: "e-307" },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|32dc4b5c-04c0-f225-43cf-f4add7c15c15",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|32dc4b5c-04c0-f225-43cf-f4add7c15c15",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: 0,
				direction: "BOTTOM",
				effectIn: !0,
			},
			createdOn: 0x18fc9366530,
		},
		"e-328": {
			id: "e-328",
			name: "",
			animationType: "custom",
			eventTypeId: "PAGE_SCROLL",
			action: {
				id: "",
				actionTypeId: "GENERAL_CONTINUOUS_ACTION",
				config: { actionListId: "a-96", affectedElements: {}, duration: 0 },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: [
				{
					continuousParameterGroupId: "a-96-p",
					smoothing: 50,
					startsEntering: !0,
					addStartOffset: !1,
					addOffsetValue: 50,
					startsExiting: !1,
					addEndOffset: !1,
					endOffsetValue: 50,
				},
			],
			createdOn: 0x18fde54be19,
		},
		"e-337": {
			id: "e-337",
			name: "",
			animationType: "custom",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-57",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-361",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "7b6d945a-0109-72f8-feba-d41885a2ac53",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "7b6d945a-0109-72f8-feba-d41885a2ac53",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18fed749b7f,
		},
		"e-339": {
			id: "e-339",
			name: "",
			animationType: "preset",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "SLIDE_EFFECT",
				instant: !1,
				config: { actionListId: "slideInBottom", autoStopEventId: "e-349" },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|e08f249b-85ca-367f-ffee-88e61ac23b06",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|e08f249b-85ca-367f-ffee-88e61ac23b06",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: 600,
				direction: "BOTTOM",
				effectIn: !0,
			},
			createdOn: 0x18f15ddd05d,
		},
		"e-341": {
			id: "e-341",
			name: "",
			animationType: "preset",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "SLIDE_EFFECT",
				instant: !1,
				config: { actionListId: "slideInBottom", autoStopEventId: "e-352" },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|e08f249b-85ca-367f-ffee-88e61ac23ace",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|e08f249b-85ca-367f-ffee-88e61ac23ace",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: 0,
				direction: "BOTTOM",
				effectIn: !0,
			},
			createdOn: 0x18fc9534dff,
		},
		"e-342": {
			id: "e-342",
			name: "",
			animationType: "preset",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "SLIDE_EFFECT",
				instant: !1,
				config: { actionListId: "slideInBottom", autoStopEventId: "e-366" },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|e08f249b-85ca-367f-ffee-88e61ac23b14",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|e08f249b-85ca-367f-ffee-88e61ac23b14",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: 750,
				direction: "BOTTOM",
				effectIn: !0,
			},
			createdOn: 0x18f15ddd05d,
		},
		"e-346": {
			id: "e-346",
			name: "",
			animationType: "preset",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "SLIDE_EFFECT",
				instant: !1,
				config: { actionListId: "slideInBottom", autoStopEventId: "e-354" },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|e08f249b-85ca-367f-ffee-88e61ac23adc",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|e08f249b-85ca-367f-ffee-88e61ac23adc",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: 150,
				direction: "BOTTOM",
				effectIn: !0,
			},
			createdOn: 0x18fc9532c5d,
		},
		"e-347": {
			id: "e-347",
			name: "",
			animationType: "custom",
			eventTypeId: "MOUSE_OUT",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-122",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-353",
				},
			},
			mediaQueries: ["main"],
			target: {
				selector: ".solutioncarditem",
				originalId:
					"673ee4cd088aa593ca5ba409|e08f249b-85ca-367f-ffee-88e61ac23b06",
				appliesTo: "CLASS",
			},
			targets: [
				{
					selector: ".solutioncarditem",
					originalId:
						"673ee4cd088aa593ca5ba409|e08f249b-85ca-367f-ffee-88e61ac23b06",
					appliesTo: "CLASS",
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x17f9dd75c18,
		},
		"e-353": {
			id: "e-353",
			name: "",
			animationType: "custom",
			eventTypeId: "MOUSE_OVER",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-123",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-347",
				},
			},
			mediaQueries: ["main"],
			target: {
				selector: ".solutioncarditem",
				originalId:
					"673ee4cd088aa593ca5ba409|e08f249b-85ca-367f-ffee-88e61ac23b06",
				appliesTo: "CLASS",
			},
			targets: [
				{
					selector: ".solutioncarditem",
					originalId:
						"673ee4cd088aa593ca5ba409|e08f249b-85ca-367f-ffee-88e61ac23b06",
					appliesTo: "CLASS",
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x17f9dd75c15,
		},
		"e-355": {
			id: "e-355",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_CLICK",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-105",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-356",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|e4088d6d-5a5d-2e0f-f392-2cc070a8f910",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|e4088d6d-5a5d-2e0f-f392-2cc070a8f910",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18fed992540,
		},
		"e-359": {
			id: "e-359",
			name: "",
			animationType: "custom",
			eventTypeId: "MOUSE_OVER",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-106",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-360",
				},
			},
			mediaQueries: ["main"],
			target: {
				id: "673ee4cd088aa593ca5ba409|9d0cb199-41a3-a95a-8615-44341f6eece6",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|9d0cb199-41a3-a95a-8615-44341f6eece6",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18fe3c0e5a5,
		},
		"e-361": {
			id: "e-361",
			name: "",
			animationType: "custom",
			eventTypeId: "MOUSE_OVER",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-107",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-362",
				},
			},
			mediaQueries: ["main", "medium"],
			target: {
				id: "673ee4cd088aa593ca5ba409|9d0cb199-41a3-a95a-8615-44341f6eecf0",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|9d0cb199-41a3-a95a-8615-44341f6eecf0",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18fe3c116cb,
		},
		"e-363": {
			id: "e-363",
			name: "",
			animationType: "custom",
			eventTypeId: "MOUSE_OVER",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-108",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-364",
				},
			},
			mediaQueries: ["main", "medium"],
			target: {
				id: "673ee4cd088aa593ca5ba409|9d0cb199-41a3-a95a-8615-44341f6eecfa",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|9d0cb199-41a3-a95a-8615-44341f6eecfa",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18fe3c14732,
		},
		"e-365": {
			id: "e-365",
			name: "",
			animationType: "custom",
			eventTypeId: "MOUSE_OVER",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-109",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-366",
				},
			},
			mediaQueries: ["main", "medium"],
			target: {
				id: "673ee4cd088aa593ca5ba409|9d0cb199-41a3-a95a-8615-44341f6eed04",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|9d0cb199-41a3-a95a-8615-44341f6eed04",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18fe6f7b519,
		},
		"e-367": {
			id: "e-367",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OVER",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-85",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-368",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|6c312ec9-c9a1-2a61-81ef-6dadde23e860",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|6c312ec9-c9a1-2a61-81ef-6dadde23e860",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x19015808347,
		},
		"e-368": {
			id: "e-368",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OUT",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-86",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-367",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|6c312ec9-c9a1-2a61-81ef-6dadde23e860",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|6c312ec9-c9a1-2a61-81ef-6dadde23e860",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x19015808347,
		},
		"e-369": {
			id: "e-369",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OVER",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-85",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-370",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|5612280e-de71-16fc-3ebd-2c298ffec056",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|5612280e-de71-16fc-3ebd-2c298ffec056",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x190158088a7,
		},
		"e-370": {
			id: "e-370",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OUT",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-86",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-369",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|5612280e-de71-16fc-3ebd-2c298ffec056",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|5612280e-de71-16fc-3ebd-2c298ffec056",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x190158088a7,
		},
		"e-371": {
			id: "e-371",
			name: "",
			animationType: "custom",
			eventTypeId: "PAGE_SCROLL",
			action: {
				id: "",
				actionTypeId: "GENERAL_CONTINUOUS_ACTION",
				config: { actionListId: "a-111", affectedElements: {}, duration: 0 },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba449",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba449",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: [
				{
					continuousParameterGroupId: "a-111-p",
					smoothing: 88,
					startsEntering: !0,
					addStartOffset: !1,
					addOffsetValue: 50,
					startsExiting: !1,
					addEndOffset: !1,
					endOffsetValue: 50,
				},
			],
			createdOn: 0x1912c570bd5,
		},
		"e-372": {
			id: "e-372",
			name: "",
			animationType: "preset",
			eventTypeId: "DROPDOWN_OPEN",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-112",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-373",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				selector: ".dropdown",
				originalId:
					"673ee4cd088aa593ca5ba449|5e70bcd1-a16b-fea7-2684-51fd76e51c75",
				appliesTo: "CLASS",
			},
			targets: [
				{
					selector: ".dropdown",
					originalId:
						"673ee4cd088aa593ca5ba449|5e70bcd1-a16b-fea7-2684-51fd76e51c75",
					appliesTo: "CLASS",
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x1912c6eee88,
		},
		"e-373": {
			id: "e-373",
			name: "",
			animationType: "preset",
			eventTypeId: "DROPDOWN_CLOSE",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-113",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-372",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				selector: ".dropdown",
				originalId:
					"673ee4cd088aa593ca5ba449|5e70bcd1-a16b-fea7-2684-51fd76e51c75",
				appliesTo: "CLASS",
			},
			targets: [
				{
					selector: ".dropdown",
					originalId:
						"673ee4cd088aa593ca5ba449|5e70bcd1-a16b-fea7-2684-51fd76e51c75",
					appliesTo: "CLASS",
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x1912c6eee88,
		},
		"e-387": {
			id: "e-387",
			name: "",
			animationType: "preset",
			eventTypeId: "PAGE_SCROLL",
			action: {
				id: "",
				actionTypeId: "GENERAL_CONTINUOUS_ACTION",
				config: { actionListId: "a-111", affectedElements: {}, duration: 0 },
			},
			mediaQueries: ["main", "medium"],
			target: {
				id: "673ee4cd088aa593ca5ba465",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba465",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: [
				{
					continuousParameterGroupId: "a-111-p",
					smoothing: 88,
					startsEntering: !0,
					addStartOffset: !1,
					addOffsetValue: 50,
					startsExiting: !1,
					addEndOffset: !1,
					endOffsetValue: 50,
				},
			],
			createdOn: 0x1912d12a5b4,
		},
		"e-394": {
			id: "e-394",
			name: "",
			animationType: "preset",
			eventTypeId: "PAGE_SCROLL",
			action: {
				id: "",
				actionTypeId: "GENERAL_CONTINUOUS_ACTION",
				config: { actionListId: "a-111", affectedElements: {}, duration: 0 },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47b",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47b",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: [
				{
					continuousParameterGroupId: "a-111-p",
					smoothing: 88,
					startsEntering: !0,
					addStartOffset: !1,
					addOffsetValue: 50,
					startsExiting: !1,
					addEndOffset: !1,
					endOffsetValue: 50,
				},
			],
			createdOn: 0x191991b76ab,
		},
		"e-401": {
			id: "e-401",
			name: "",
			animationType: "preset",
			eventTypeId: "PAGE_SCROLL",
			action: {
				id: "",
				actionTypeId: "GENERAL_CONTINUOUS_ACTION",
				config: { actionListId: "a-111", affectedElements: {}, duration: 0 },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47c",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47c",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: [
				{
					continuousParameterGroupId: "a-111-p",
					smoothing: 88,
					startsEntering: !0,
					addStartOffset: !1,
					addOffsetValue: 50,
					startsExiting: !1,
					addEndOffset: !1,
					endOffsetValue: 50,
				},
			],
			createdOn: 0x191991c9658,
		},
		"e-408": {
			id: "e-408",
			name: "",
			animationType: "preset",
			eventTypeId: "PAGE_SCROLL",
			action: {
				id: "",
				actionTypeId: "GENERAL_CONTINUOUS_ACTION",
				config: { actionListId: "a-111", affectedElements: {}, duration: 0 },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47d",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47d",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: [
				{
					continuousParameterGroupId: "a-111-p",
					smoothing: 88,
					startsEntering: !0,
					addStartOffset: !1,
					addOffsetValue: 50,
					startsExiting: !1,
					addEndOffset: !1,
					endOffsetValue: 50,
				},
			],
			createdOn: 0x191991d7f8e,
		},
		"e-456": {
			id: "e-456",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OVER",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-125",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-457",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47e|4d0adb45-36e2-6921-dee2-e2fec7246469",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47e|4d0adb45-36e2-6921-dee2-e2fec7246469",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18a402821c1,
		},
		"e-457": {
			id: "e-457",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OUT",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-126",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-456",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47e|4d0adb45-36e2-6921-dee2-e2fec7246469",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47e|4d0adb45-36e2-6921-dee2-e2fec7246469",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18a402821c1,
		},
		"e-458": {
			id: "e-458",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OVER",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-125",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-459",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47e|47ccc824-2a4c-f6cb-1223-e2215c8405fa",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47e|47ccc824-2a4c-f6cb-1223-e2215c8405fa",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18a402821c1,
		},
		"e-459": {
			id: "e-459",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OUT",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-126",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-458",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47e|47ccc824-2a4c-f6cb-1223-e2215c8405fa",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47e|47ccc824-2a4c-f6cb-1223-e2215c8405fa",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18a402821c1,
		},
		"e-460": {
			id: "e-460",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OVER",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-125",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-461",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47e|a6a601a6-7e05-4756-3fed-86c2e73eebb8",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47e|a6a601a6-7e05-4756-3fed-86c2e73eebb8",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18a402821c1,
		},
		"e-461": {
			id: "e-461",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OUT",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-126",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-460",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47e|a6a601a6-7e05-4756-3fed-86c2e73eebb8",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47e|a6a601a6-7e05-4756-3fed-86c2e73eebb8",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18a402821c1,
		},
		"e-462": {
			id: "e-462",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OVER",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-125",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-463",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47e|9917ef52-3e8c-2823-ec0a-0e020ff8a437",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47e|9917ef52-3e8c-2823-ec0a-0e020ff8a437",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x191fa73975b,
		},
		"e-463": {
			id: "e-463",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OUT",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-126",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-462",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47e|9917ef52-3e8c-2823-ec0a-0e020ff8a437",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47e|9917ef52-3e8c-2823-ec0a-0e020ff8a437",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x191fa73975b,
		},
		"e-464": {
			id: "e-464",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OVER",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-125",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-465",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47e|9917ef52-3e8c-2823-ec0a-0e020ff8a443",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47e|9917ef52-3e8c-2823-ec0a-0e020ff8a443",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x191fa73975b,
		},
		"e-465": {
			id: "e-465",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OUT",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-126",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-464",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47e|9917ef52-3e8c-2823-ec0a-0e020ff8a443",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47e|9917ef52-3e8c-2823-ec0a-0e020ff8a443",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x191fa73975b,
		},
		"e-466": {
			id: "e-466",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OVER",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-125",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-467",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47e|9917ef52-3e8c-2823-ec0a-0e020ff8a44f",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47e|9917ef52-3e8c-2823-ec0a-0e020ff8a44f",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x191fa73975b,
		},
		"e-467": {
			id: "e-467",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OUT",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-126",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-466",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47e|9917ef52-3e8c-2823-ec0a-0e020ff8a44f",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47e|9917ef52-3e8c-2823-ec0a-0e020ff8a44f",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x191fa73975b,
		},
		"e-468": {
			id: "e-468",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OVER",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-125",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-469",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47e|ee65b50e-ccf5-c8d0-99b9-c8ad370d378c",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47e|ee65b50e-ccf5-c8d0-99b9-c8ad370d378c",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x191fa73a172,
		},
		"e-469": {
			id: "e-469",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OUT",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-126",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-468",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47e|ee65b50e-ccf5-c8d0-99b9-c8ad370d378c",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47e|ee65b50e-ccf5-c8d0-99b9-c8ad370d378c",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x191fa73a172,
		},
		"e-470": {
			id: "e-470",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OVER",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-125",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-471",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47e|ee65b50e-ccf5-c8d0-99b9-c8ad370d3798",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47e|ee65b50e-ccf5-c8d0-99b9-c8ad370d3798",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x191fa73a172,
		},
		"e-471": {
			id: "e-471",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OUT",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-126",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-470",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47e|ee65b50e-ccf5-c8d0-99b9-c8ad370d3798",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47e|ee65b50e-ccf5-c8d0-99b9-c8ad370d3798",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x191fa73a172,
		},
		"e-472": {
			id: "e-472",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OVER",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-125",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-473",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47e|ee65b50e-ccf5-c8d0-99b9-c8ad370d37a4",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47e|ee65b50e-ccf5-c8d0-99b9-c8ad370d37a4",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x191fa73a172,
		},
		"e-473": {
			id: "e-473",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OUT",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-126",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-472",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47e|ee65b50e-ccf5-c8d0-99b9-c8ad370d37a4",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47e|ee65b50e-ccf5-c8d0-99b9-c8ad370d37a4",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x191fa73a172,
		},
		"e-531": {
			id: "e-531",
			name: "",
			animationType: "preset",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "SLIDE_EFFECT",
				instant: !1,
				config: { actionListId: "slideInTop", autoStopEventId: "e-532" },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47f|b4c372e3-e1d7-037e-1be7-40a47028061f",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47f|b4c372e3-e1d7-037e-1be7-40a47028061f",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: 0,
				direction: "TOP",
				effectIn: !0,
			},
			createdOn: 0x19222eda4d3,
		},
		"e-533": {
			id: "e-533",
			name: "",
			animationType: "preset",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "SLIDE_EFFECT",
				instant: !1,
				config: { actionListId: "slideInBottom", autoStopEventId: "e-534" },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47f|2601d854-0cc9-1b70-cee3-e1942637f40a",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47f|2601d854-0cc9-1b70-cee3-e1942637f40a",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: 300,
				direction: "BOTTOM",
				effectIn: !0,
			},
			createdOn: 0x19222edc519,
		},
		"e-535": {
			id: "e-535",
			name: "",
			animationType: "preset",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "SLIDE_EFFECT",
				instant: !1,
				config: { actionListId: "slideInBottom", autoStopEventId: "e-536" },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47f|2601d854-0cc9-1b70-cee3-e1942637f40b",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47f|2601d854-0cc9-1b70-cee3-e1942637f40b",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: 500,
				direction: "BOTTOM",
				effectIn: !0,
			},
			createdOn: 0x19222edeb9f,
		},
		"e-537": {
			id: "e-537",
			name: "",
			animationType: "preset",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "SLIDE_EFFECT",
				instant: !1,
				config: { actionListId: "slideInBottom", autoStopEventId: "e-538" },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47f|2601d854-0cc9-1b70-cee3-e1942637f40c",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47f|2601d854-0cc9-1b70-cee3-e1942637f40c",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: 800,
				direction: "BOTTOM",
				effectIn: !0,
			},
			createdOn: 0x19222ee0e67,
		},
		"e-539": {
			id: "e-539",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OVER",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-125",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-540",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47f|0b93d7ae-5a35-ab7c-fc70-e440c51b5605",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47f|0b93d7ae-5a35-ab7c-fc70-e440c51b5605",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x19228f570eb,
		},
		"e-540": {
			id: "e-540",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OUT",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-126",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-539",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47f|0b93d7ae-5a35-ab7c-fc70-e440c51b5605",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47f|0b93d7ae-5a35-ab7c-fc70-e440c51b5605",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x19228f570eb,
		},
		"e-541": {
			id: "e-541",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OVER",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-125",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-542",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47f|0b93d7ae-5a35-ab7c-fc70-e440c51b5611",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47f|0b93d7ae-5a35-ab7c-fc70-e440c51b5611",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x19228f570eb,
		},
		"e-542": {
			id: "e-542",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OUT",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-126",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-541",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47f|0b93d7ae-5a35-ab7c-fc70-e440c51b5611",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47f|0b93d7ae-5a35-ab7c-fc70-e440c51b5611",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x19228f570eb,
		},
		"e-543": {
			id: "e-543",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OVER",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-125",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-544",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47f|0b93d7ae-5a35-ab7c-fc70-e440c51b561d",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47f|0b93d7ae-5a35-ab7c-fc70-e440c51b561d",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x19228f570eb,
		},
		"e-544": {
			id: "e-544",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OUT",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-126",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-543",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47f|0b93d7ae-5a35-ab7c-fc70-e440c51b561d",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47f|0b93d7ae-5a35-ab7c-fc70-e440c51b561d",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x19228f570eb,
		},
		"e-545": {
			id: "e-545",
			name: "",
			animationType: "custom",
			eventTypeId: "PAGE_SCROLL",
			action: {
				id: "",
				actionTypeId: "GENERAL_CONTINUOUS_ACTION",
				config: { actionListId: "a-129", affectedElements: {}, duration: 0 },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47e",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47e",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: [
				{
					continuousParameterGroupId: "a-129-p",
					smoothing: 50,
					startsEntering: !0,
					addStartOffset: !1,
					addOffsetValue: 50,
					startsExiting: !1,
					addEndOffset: !1,
					endOffsetValue: 50,
				},
			],
			createdOn: 0x1925167fc81,
		},
		"e-546": {
			id: "e-546",
			name: "",
			animationType: "preset",
			eventTypeId: "DROPDOWN_OPEN",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-130",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-547",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				selector: ".dropdown",
				originalId: "9de3c672-81d3-7254-43d5-8f93d34ee04e",
				appliesTo: "CLASS",
			},
			targets: [
				{
					selector: ".dropdown",
					originalId: "9de3c672-81d3-7254-43d5-8f93d34ee04e",
					appliesTo: "CLASS",
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x19251afa050,
		},
		"e-547": {
			id: "e-547",
			name: "",
			animationType: "preset",
			eventTypeId: "DROPDOWN_CLOSE",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-131",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-546",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				selector: ".dropdown",
				originalId: "9de3c672-81d3-7254-43d5-8f93d34ee04e",
				appliesTo: "CLASS",
			},
			targets: [
				{
					selector: ".dropdown",
					originalId: "9de3c672-81d3-7254-43d5-8f93d34ee04e",
					appliesTo: "CLASS",
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x19251afa050,
		},
		"e-548": {
			id: "e-548",
			animationType: "custom",
			eventTypeId: "MOUSE_CLICK",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-132",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-549",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "4b24e683-1a33-92d2-81cf-0b09710c501b",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "4b24e683-1a33-92d2-81cf-0b09710c501b",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x16959e3d6fe,
		},
		"e-549": {
			id: "e-549",
			animationType: "custom",
			eventTypeId: "MOUSE_SECOND_CLICK",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-133",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-548",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "4b24e683-1a33-92d2-81cf-0b09710c501b",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "4b24e683-1a33-92d2-81cf-0b09710c501b",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x16959e3d701,
		},
		"e-550": {
			id: "e-550",
			name: "",
			animationType: "custom",
			eventTypeId: "PAGE_SCROLL",
			action: {
				id: "",
				actionTypeId: "GENERAL_CONTINUOUS_ACTION",
				config: { actionListId: "a-134", affectedElements: {}, duration: 0 },
			},
			mediaQueries: ["small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba465",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba465",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: [
				{
					continuousParameterGroupId: "a-134-p",
					smoothing: 50,
					startsEntering: !0,
					addStartOffset: !1,
					addOffsetValue: 50,
					startsExiting: !1,
					addEndOffset: !1,
					endOffsetValue: 50,
				},
			],
			createdOn: 0x1928a66d1ae,
		},
		"e-551": {
			id: "e-551",
			name: "",
			animationType: "custom",
			eventTypeId: "PAGE_START",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-135",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-552",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba449",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba449",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x1928a6acd8a,
		},
		"e-553": {
			id: "e-553",
			name: "",
			animationType: "custom",
			eventTypeId: "PAGE_START",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-136",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-554",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba449",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba449",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x1928a6c19d3,
		},
		"e-555": {
			id: "e-555",
			name: "",
			animationType: "custom",
			eventTypeId: "PAGE_START",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-135",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-556",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47b",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47b",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x1928a70f677,
		},
		"e-557": {
			id: "e-557",
			name: "",
			animationType: "custom",
			eventTypeId: "PAGE_START",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-136",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-558",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47b",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47b",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x1928a711558,
		},
		"e-559": {
			id: "e-559",
			name: "",
			animationType: "custom",
			eventTypeId: "PAGE_START",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-135",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-560",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47c",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47c",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x1928a715fda,
		},
		"e-561": {
			id: "e-561",
			name: "",
			animationType: "custom",
			eventTypeId: "PAGE_START",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-136",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-562",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47c",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47c",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x1928a7184f0,
		},
		"e-563": {
			id: "e-563",
			name: "",
			animationType: "custom",
			eventTypeId: "PAGE_START",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-135",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-564",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47d",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47d",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x1928a71b561,
		},
		"e-565": {
			id: "e-565",
			name: "",
			animationType: "custom",
			eventTypeId: "PAGE_START",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-136",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-566",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47d",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47d",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x1928a71d700,
		},
		"e-571": {
			id: "e-571",
			name: "",
			animationType: "custom",
			eventTypeId: "PAGE_START",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-135",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-572",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba465",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba465",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x1928a72848c,
		},
		"e-573": {
			id: "e-573",
			name: "",
			animationType: "custom",
			eventTypeId: "PAGE_START",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-136",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-574",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba465",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba465",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x1928a72a551,
		},
		"e-576": {
			id: "e-576",
			name: "",
			animationType: "custom",
			eventTypeId: "PAGE_SCROLL",
			action: {
				id: "",
				actionTypeId: "GENERAL_CONTINUOUS_ACTION",
				config: { actionListId: "a-134", affectedElements: {}, duration: 0 },
			},
			mediaQueries: ["small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47d",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47d",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: [
				{
					continuousParameterGroupId: "a-134-p",
					smoothing: 50,
					startsEntering: !0,
					addStartOffset: !1,
					addOffsetValue: 50,
					startsExiting: !1,
					addEndOffset: !1,
					endOffsetValue: 50,
				},
			],
			createdOn: 0x1928a73162c,
		},
		"e-577": {
			id: "e-577",
			name: "",
			animationType: "custom",
			eventTypeId: "PAGE_SCROLL",
			action: {
				id: "",
				actionTypeId: "GENERAL_CONTINUOUS_ACTION",
				config: { actionListId: "a-134", affectedElements: {}, duration: 0 },
			},
			mediaQueries: ["small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47c",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47c",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: [
				{
					continuousParameterGroupId: "a-134-p",
					smoothing: 50,
					startsEntering: !0,
					addStartOffset: !1,
					addOffsetValue: 50,
					startsExiting: !1,
					addEndOffset: !1,
					endOffsetValue: 50,
				},
			],
			createdOn: 0x1928a73496f,
		},
		"e-578": {
			id: "e-578",
			name: "",
			animationType: "custom",
			eventTypeId: "PAGE_SCROLL",
			action: {
				id: "",
				actionTypeId: "GENERAL_CONTINUOUS_ACTION",
				config: { actionListId: "a-134", affectedElements: {}, duration: 0 },
			},
			mediaQueries: ["small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba47b",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba47b",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: [
				{
					continuousParameterGroupId: "a-134-p",
					smoothing: 50,
					startsEntering: !0,
					addStartOffset: !1,
					addOffsetValue: 50,
					startsExiting: !1,
					addEndOffset: !1,
					endOffsetValue: 50,
				},
			],
			createdOn: 0x1928a737279,
		},
		"e-579": {
			id: "e-579",
			name: "",
			animationType: "custom",
			eventTypeId: "PAGE_SCROLL",
			action: {
				id: "",
				actionTypeId: "GENERAL_CONTINUOUS_ACTION",
				config: { actionListId: "a-134", affectedElements: {}, duration: 0 },
			},
			mediaQueries: ["small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba449",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba449",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: [
				{
					continuousParameterGroupId: "a-134-p",
					smoothing: 50,
					startsEntering: !0,
					addStartOffset: !1,
					addOffsetValue: 50,
					startsExiting: !1,
					addEndOffset: !1,
					endOffsetValue: 50,
				},
			],
			createdOn: 0x1928a73c162,
		},
		"e-580": {
			id: "e-580",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OVER",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-85",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-581",
				},
			},
			mediaQueries: ["main"],
			target: {
				id: "673ee4cd088aa593ca5ba409|47a634ae-664c-4493-d2aa-dd61b405059d",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|47a634ae-664c-4493-d2aa-dd61b405059d",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x1928ecdd3d7,
		},
		"e-581": {
			id: "e-581",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_OUT",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-86",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-580",
				},
			},
			mediaQueries: ["main"],
			target: {
				id: "673ee4cd088aa593ca5ba409|47a634ae-664c-4493-d2aa-dd61b405059d",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|47a634ae-664c-4493-d2aa-dd61b405059d",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x1928ecdd3d7,
		},
		"e-582": {
			id: "e-582",
			name: "",
			animationType: "preset",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "SLIDE_EFFECT",
				instant: !1,
				config: { actionListId: "slideInBottom", autoStopEventId: "e-583" },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|e08f249b-85ca-367f-ffee-88e61ac23aea",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|e08f249b-85ca-367f-ffee-88e61ac23aea",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: 300,
				direction: "BOTTOM",
				effectIn: !0,
			},
			createdOn: 0x1928f0b8514,
		},
		"e-584": {
			id: "e-584",
			name: "",
			animationType: "preset",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "SLIDE_EFFECT",
				instant: !1,
				config: { actionListId: "slideInBottom", autoStopEventId: "e-585" },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|e08f249b-85ca-367f-ffee-88e61ac23af8",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|e08f249b-85ca-367f-ffee-88e61ac23af8",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: 450,
				direction: "BOTTOM",
				effectIn: !0,
			},
			createdOn: 0x1928f0bda95,
		},
		"e-586": {
			id: "e-586",
			name: "",
			animationType: "preset",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "SLIDE_EFFECT",
				instant: !1,
				config: { actionListId: "slideInLeft", autoStopEventId: "e-587" },
			},
			mediaQueries: ["medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|c5f658dd-5043-c45f-97b7-01f583c558dc",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|c5f658dd-5043-c45f-97b7-01f583c558dc",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: 0,
				direction: "LEFT",
				effectIn: !0,
			},
			createdOn: 0x19290640382,
		},
		"e-588": {
			id: "e-588",
			name: "",
			animationType: "preset",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "SLIDE_EFFECT",
				instant: !1,
				config: { actionListId: "slideInBottom", autoStopEventId: "e-589" },
			},
			mediaQueries: ["medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|c5f658dd-5043-c45f-97b7-01f583c558cb",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|c5f658dd-5043-c45f-97b7-01f583c558cb",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: 150,
				direction: "BOTTOM",
				effectIn: !0,
			},
			createdOn: 0x19290643478,
		},
		"e-590": {
			id: "e-590",
			name: "",
			animationType: "preset",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "SLIDE_EFFECT",
				instant: !1,
				config: { actionListId: "slideInBottom", autoStopEventId: "e-591" },
			},
			mediaQueries: ["medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|c5f658dd-5043-c45f-97b7-01f583c558d0",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|c5f658dd-5043-c45f-97b7-01f583c558d0",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: 300,
				direction: "BOTTOM",
				effectIn: !0,
			},
			createdOn: 0x192906478fd,
		},
		"e-592": {
			id: "e-592",
			name: "",
			animationType: "preset",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "SLIDE_EFFECT",
				instant: !1,
				config: { actionListId: "slideInBottom", autoStopEventId: "e-593" },
			},
			mediaQueries: ["medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|c5f658dd-5043-c45f-97b7-01f583c558d5",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|c5f658dd-5043-c45f-97b7-01f583c558d5",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: 450,
				direction: "BOTTOM",
				effectIn: !0,
			},
			createdOn: 0x1929064baef,
		},
		"e-594": {
			id: "e-594",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_CLICK",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-137",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-595",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "6a34980c-1919-88f5-c825-2ae56ffd3b96",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "6a34980c-1919-88f5-c825-2ae56ffd3b96",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x192907a6017,
		},
		"e-595": {
			id: "e-595",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_SECOND_CLICK",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-138",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-594",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "6a34980c-1919-88f5-c825-2ae56ffd3b96",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "6a34980c-1919-88f5-c825-2ae56ffd3b96",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x192907a6017,
		},
		"e-596": {
			id: "e-596",
			name: "",
			animationType: "preset",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "SLIDE_EFFECT",
				instant: !1,
				config: { actionListId: "slideInTop", autoStopEventId: "e-597" },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "6a34980c-1919-88f5-c825-2ae56ffd3b80",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "6a34980c-1919-88f5-c825-2ae56ffd3b80",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: 150,
				direction: "TOP",
				effectIn: !0,
			},
			createdOn: 0x192907a6017,
		},
		"e-598": {
			id: "e-598",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_CLICK",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-139",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-599",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "6a34980c-1919-88f5-c825-2ae56ffd3b84",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "6a34980c-1919-88f5-c825-2ae56ffd3b84",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x192907a6017,
		},
		"e-600": {
			id: "e-600",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_CLICK",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-140",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-601",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "6a34980c-1919-88f5-c825-2ae56ffd3b9c",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "6a34980c-1919-88f5-c825-2ae56ffd3b9c",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x192907a6017,
		},
		"e-601": {
			id: "e-601",
			name: "",
			animationType: "preset",
			eventTypeId: "MOUSE_SECOND_CLICK",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-141",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-600",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "6a34980c-1919-88f5-c825-2ae56ffd3b9c",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "6a34980c-1919-88f5-c825-2ae56ffd3b9c",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x192907a6017,
		},
		"e-602": {
			id: "e-602",
			name: "",
			animationType: "preset",
			eventTypeId: "DROPDOWN_OPEN",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-142",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-603",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				selector: ".dropdown",
				originalId:
					"673ee4cd088aa593ca5ba449|fb7ee785-cf0a-1342-d905-2b2eaf4ecd31",
				appliesTo: "CLASS",
			},
			targets: [
				{
					selector: ".dropdown",
					originalId:
						"673ee4cd088aa593ca5ba449|fb7ee785-cf0a-1342-d905-2b2eaf4ecd31",
					appliesTo: "CLASS",
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x192907e3507,
		},
		"e-603": {
			id: "e-603",
			name: "",
			animationType: "preset",
			eventTypeId: "DROPDOWN_CLOSE",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-143",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-602",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				selector: ".dropdown",
				originalId:
					"673ee4cd088aa593ca5ba449|fb7ee785-cf0a-1342-d905-2b2eaf4ecd31",
				appliesTo: "CLASS",
			},
			targets: [
				{
					selector: ".dropdown",
					originalId:
						"673ee4cd088aa593ca5ba449|fb7ee785-cf0a-1342-d905-2b2eaf4ecd31",
					appliesTo: "CLASS",
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x192907e3507,
		},
		"e-606": {
			id: "e-606",
			name: "",
			animationType: "custom",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-106",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-607",
				},
			},
			mediaQueries: ["medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|9d0cb199-41a3-a95a-8615-44341f6eecec",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|9d0cb199-41a3-a95a-8615-44341f6eecec",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: 0,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x19293d5c3b5,
		},
		"e-610": {
			id: "e-610",
			name: "",
			animationType: "custom",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-145",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-611",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409|77f4271b-125b-a1f8-7371-6ff490361a55",
				appliesTo: "ELEMENT",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409|77f4271b-125b-a1f8-7371-6ff490361a55",
					appliesTo: "ELEMENT",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x18ef3a677de,
		},
		"e-612": {
			id: "e-612",
			name: "",
			animationType: "custom",
			eventTypeId: "PAGE_START",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-146",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-613",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !0,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x192944f3e04,
		},
		"e-614": {
			id: "e-614",
			name: "",
			animationType: "custom",
			eventTypeId: "PAGE_START",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-144",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-615",
				},
			},
			mediaQueries: ["main", "medium"],
			target: {
				id: "673ee4cd088aa593ca5ba409",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !0,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x19294502d40,
		},
		"e-616": {
			id: "e-616",
			name: "",
			animationType: "custom",
			eventTypeId: "PAGE_START",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-147",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-617",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x192945a06ad,
		},
		"e-618": {
			id: "e-618",
			name: "",
			animationType: "custom",
			eventTypeId: "PAGE_SCROLL",
			action: {
				id: "",
				actionTypeId: "GENERAL_CONTINUOUS_ACTION",
				config: { actionListId: "a-148", affectedElements: {}, duration: 0 },
			},
			mediaQueries: ["medium", "small", "tiny"],
			target: {
				id: "673ee4cd088aa593ca5ba409",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "673ee4cd088aa593ca5ba409",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: [
				{
					continuousParameterGroupId: "a-148-p",
					smoothing: 50,
					startsEntering: !0,
					addStartOffset: !1,
					addOffsetValue: 50,
					startsExiting: !1,
					addEndOffset: !1,
					endOffsetValue: 50,
				},
			],
			createdOn: 0x192f62735ba,
		},
		"e-619": {
			id: "e-619",
			name: "",
			animationType: "preset",
			eventTypeId: "PAGE_SCROLL",
			action: {
				id: "",
				actionTypeId: "GENERAL_CONTINUOUS_ACTION",
				config: { actionListId: "a-111", affectedElements: {}, duration: 0 },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "67407d91b0d2ddcb7629919c",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "67407d91b0d2ddcb7629919c",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: [
				{
					continuousParameterGroupId: "a-111-p",
					smoothing: 88,
					startsEntering: !0,
					addStartOffset: !1,
					addOffsetValue: 50,
					startsExiting: !1,
					addEndOffset: !1,
					endOffsetValue: 50,
				},
			],
			createdOn: 0x19353ea84a2,
		},
		"e-620": {
			id: "e-620",
			name: "",
			animationType: "preset",
			eventTypeId: "PAGE_START",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-135",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-621",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "67407d91b0d2ddcb7629919c",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "67407d91b0d2ddcb7629919c",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x19353ea84a2,
		},
		"e-622": {
			id: "e-622",
			name: "",
			animationType: "preset",
			eventTypeId: "PAGE_START",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-136",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-623",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "67407d91b0d2ddcb7629919c",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "67407d91b0d2ddcb7629919c",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x19353ea84a2,
		},
		"e-624": {
			id: "e-624",
			name: "",
			animationType: "preset",
			eventTypeId: "PAGE_SCROLL",
			action: {
				id: "",
				actionTypeId: "GENERAL_CONTINUOUS_ACTION",
				config: { actionListId: "a-134", affectedElements: {}, duration: 0 },
			},
			mediaQueries: ["small", "tiny"],
			target: {
				id: "67407d91b0d2ddcb7629919c",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "67407d91b0d2ddcb7629919c",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: [
				{
					continuousParameterGroupId: "a-134-p",
					smoothing: 50,
					startsEntering: !0,
					addStartOffset: !1,
					addOffsetValue: 50,
					startsExiting: !1,
					addEndOffset: !1,
					endOffsetValue: 50,
				},
			],
			createdOn: 0x19353ea84a2,
		},
		"e-625": {
			id: "e-625",
			name: "",
			animationType: "preset",
			eventTypeId: "PAGE_SCROLL",
			action: {
				id: "",
				actionTypeId: "GENERAL_CONTINUOUS_ACTION",
				config: { actionListId: "a-111", affectedElements: {}, duration: 0 },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "6745ca2335b2c1b5ba1ce114",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "6745ca2335b2c1b5ba1ce114",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: [
				{
					continuousParameterGroupId: "a-111-p",
					smoothing: 88,
					startsEntering: !0,
					addStartOffset: !1,
					addOffsetValue: 50,
					startsExiting: !1,
					addEndOffset: !1,
					endOffsetValue: 50,
				},
			],
			createdOn: 0x193689d9c30,
		},
		"e-626": {
			id: "e-626",
			name: "",
			animationType: "preset",
			eventTypeId: "PAGE_START",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-135",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-627",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "6745ca2335b2c1b5ba1ce114",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "6745ca2335b2c1b5ba1ce114",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x193689d9c30,
		},
		"e-628": {
			id: "e-628",
			name: "",
			animationType: "preset",
			eventTypeId: "PAGE_START",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-136",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-629",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "6745ca2335b2c1b5ba1ce114",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "6745ca2335b2c1b5ba1ce114",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x193689d9c30,
		},
		"e-630": {
			id: "e-630",
			name: "",
			animationType: "preset",
			eventTypeId: "PAGE_SCROLL",
			action: {
				id: "",
				actionTypeId: "GENERAL_CONTINUOUS_ACTION",
				config: { actionListId: "a-134", affectedElements: {}, duration: 0 },
			},
			mediaQueries: ["small", "tiny"],
			target: {
				id: "6745ca2335b2c1b5ba1ce114",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "6745ca2335b2c1b5ba1ce114",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: [
				{
					continuousParameterGroupId: "a-134-p",
					smoothing: 50,
					startsEntering: !0,
					addStartOffset: !1,
					addOffsetValue: 50,
					startsExiting: !1,
					addEndOffset: !1,
					endOffsetValue: 50,
				},
			],
			createdOn: 0x193689d9c30,
		},
		"e-631": {
			id: "e-631",
			name: "",
			animationType: "preset",
			eventTypeId: "PAGE_SCROLL",
			action: {
				id: "",
				actionTypeId: "GENERAL_CONTINUOUS_ACTION",
				config: { actionListId: "a-111", affectedElements: {}, duration: 0 },
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "6745cc4694494094bdf3d9cd",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "6745cc4694494094bdf3d9cd",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: [
				{
					continuousParameterGroupId: "a-111-p",
					smoothing: 88,
					startsEntering: !0,
					addStartOffset: !1,
					addOffsetValue: 50,
					startsExiting: !1,
					addEndOffset: !1,
					endOffsetValue: 50,
				},
			],
			createdOn: 0x19368a5f73e,
		},
		"e-632": {
			id: "e-632",
			name: "",
			animationType: "preset",
			eventTypeId: "PAGE_START",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-135",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-633",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "6745cc4694494094bdf3d9cd",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "6745cc4694494094bdf3d9cd",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x19368a5f73e,
		},
		"e-634": {
			id: "e-634",
			name: "",
			animationType: "preset",
			eventTypeId: "PAGE_START",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-136",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-635",
				},
			},
			mediaQueries: ["main", "medium", "small", "tiny"],
			target: {
				id: "6745cc4694494094bdf3d9cd",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "6745cc4694494094bdf3d9cd",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: null,
				scrollOffsetUnit: null,
				delay: null,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x19368a5f73e,
		},
		"e-636": {
			id: "e-636",
			name: "",
			animationType: "preset",
			eventTypeId: "PAGE_SCROLL",
			action: {
				id: "",
				actionTypeId: "GENERAL_CONTINUOUS_ACTION",
				config: { actionListId: "a-134", affectedElements: {}, duration: 0 },
			},
			mediaQueries: ["small", "tiny"],
			target: {
				id: "6745cc4694494094bdf3d9cd",
				appliesTo: "PAGE",
				styleBlockIds: [],
			},
			targets: [
				{
					id: "6745cc4694494094bdf3d9cd",
					appliesTo: "PAGE",
					styleBlockIds: [],
				},
			],
			config: [
				{
					continuousParameterGroupId: "a-134-p",
					smoothing: 50,
					startsEntering: !0,
					addStartOffset: !1,
					addOffsetValue: 50,
					startsExiting: !1,
					addEndOffset: !1,
					endOffsetValue: 50,
				},
			],
			createdOn: 0x19368a5f73e,
		},
		"e-637": {
			id: "e-637",
			name: "",
			animationType: "preset",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "PLUGIN_LOTTIE_EFFECT",
				config: { actionListId: "pluginLottie", autoStopEventId: "e-638" },
				instant: !1,
			},
			mediaQueries: ["medium", "small", "tiny"],
			target: {
				appliesTo: "ELEMENT",
				styleBlockIds: [],
				id: "673ee4cd088aa593ca5ba409|9d0cb199-41a3-a95a-8615-44341f6eecf6",
			},
			targets: [],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: 0,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x194f997c8a5,
		},
		"e-639": {
			id: "e-639",
			name: "",
			animationType: "custom",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "GENERAL_START_ACTION",
				config: {
					delay: 0,
					easing: "",
					duration: 0,
					actionListId: "a-108",
					affectedElements: {},
					playInReverse: !1,
					autoStopEventId: "e-640",
				},
			},
			mediaQueries: ["medium", "small", "tiny"],
			target: {
				appliesTo: "ELEMENT",
				styleBlockIds: [],
				id: "673ee4cd088aa593ca5ba409|9d0cb199-41a3-a95a-8615-44341f6eed00",
			},
			targets: [],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: 0,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x194f997fe81,
		},
		"e-641": {
			id: "e-641",
			name: "",
			animationType: "preset",
			eventTypeId: "SCROLL_INTO_VIEW",
			action: {
				id: "",
				actionTypeId: "PLUGIN_LOTTIE_EFFECT",
				config: { actionListId: "pluginLottie", autoStopEventId: "e-642" },
				instant: !1,
			},
			mediaQueries: ["medium", "small", "tiny"],
			target: {
				appliesTo: "ELEMENT",
				styleBlockIds: [],
				id: "673ee4cd088aa593ca5ba409|9d0cb199-41a3-a95a-8615-44341f6eed0c",
			},
			targets: [],
			config: {
				loop: !1,
				playInReverse: !1,
				scrollOffsetValue: 0,
				scrollOffsetUnit: "%",
				delay: 0,
				direction: null,
				effectIn: null,
			},
			createdOn: 0x194f99810e5,
		},
	},
	actionLists: {
		a: {
			id: "a",
			title: "hero",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-n-20",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									id: "673ee4cd088aa593ca5ba409|a7b5c963-1ac0-1681-01ca-9e294114cfae",
								},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-n-17",
							actionTypeId: "TRANSFORM_SCALE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									id: "673ee4cd088aa593ca5ba409|9a214170-633f-1045-9452-f0848cfcd7fe",
								},
								xValue: 0.2,
								yValue: 0.2,
								locked: !0,
							},
						},
						{
							id: "a-n-14",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									id: "673ee4cd088aa593ca5ba409|9a214170-633f-1045-9452-f0848cfcd7fe",
								},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-n-13",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									id: "673ee4cd088aa593ca5ba409|9a214170-633f-1045-9452-f0848cfcd7fe",
								},
								xValue: -500,
								yValue: 300,
								xUnit: "px",
								yUnit: "px",
								zUnit: "PX",
							},
						},
						{
							id: "a-n-11",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									selector: ".hero__circles",
									selectorGuids: ["52dc9b0d-c19c-bebb-e0c5-3b790f09d0ec"],
								},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-n-5",
							actionTypeId: "TRANSFORM_SCALE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									selector: ".hero__circles",
									selectorGuids: ["52dc9b0d-c19c-bebb-e0c5-3b790f09d0ec"],
								},
								xValue: 0.8,
								yValue: 0.8,
								locked: !0,
							},
						},
						{
							id: "a-n-4",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									selector: ".hero__read",
									selectorGuids: ["574ddb9b-9322-e606-13aa-fe87386a6e2f"],
								},
								yValue: 110,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
						{
							id: "a-n-3",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								yValue: 110,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
						{
							id: "a-n-2",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								xValue: -110,
								xUnit: "%",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-n-18",
							actionTypeId: "TRANSFORM_SCALE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 800,
								target: {
									id: "673ee4cd088aa593ca5ba409|9a214170-633f-1045-9452-f0848cfcd7fe",
								},
								xValue: 1,
								yValue: 1,
								locked: !0,
							},
						},
						{
							id: "a-n-12",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 500,
								target: {
									selector: ".hero__circles",
									selectorGuids: ["52dc9b0d-c19c-bebb-e0c5-3b790f09d0ec"],
								},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-n-10",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 800,
								target: {},
								xValue: 0,
								xUnit: "%",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-n-9",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 800,
								target: {},
								yValue: 0,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
						{
							id: "a-n-8",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 800,
								target: {
									selector: ".hero__read",
									selectorGuids: ["574ddb9b-9322-e606-13aa-fe87386a6e2f"],
								},
								yValue: 0,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
						{
							id: "a-n-7",
							actionTypeId: "TRANSFORM_SCALE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 800,
								target: {
									selector: ".hero__circles",
									selectorGuids: ["52dc9b0d-c19c-bebb-e0c5-3b790f09d0ec"],
								},
								xValue: 1,
								yValue: 1,
								locked: !0,
							},
						},
						{
							id: "a-n-15",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 500,
								target: {
									id: "673ee4cd088aa593ca5ba409|9a214170-633f-1045-9452-f0848cfcd7fe",
								},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-n-16",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 800,
								target: {
									id: "673ee4cd088aa593ca5ba409|9a214170-633f-1045-9452-f0848cfcd7fe",
								},
								xValue: 0,
								yValue: 0,
								xUnit: "px",
								yUnit: "px",
								zUnit: "PX",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-n-21",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 1e3,
								easing: "inOutQuad",
								duration: 500,
								target: {
									id: "673ee4cd088aa593ca5ba409|a7b5c963-1ac0-1681-01ca-9e294114cfae",
								},
								value: 1,
								unit: "",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x17ed8c9bdbb,
		},
		"a-3": {
			id: "a-3",
			title: "news",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-3-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								xValue: -110,
								xUnit: "%",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-3-n-3",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-3-n-2",
							actionTypeId: "TRANSFORM_SCALE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								xValue: 0.8,
								yValue: 0.8,
								locked: !0,
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-3-n-4",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 800,
								target: {},
								xValue: 0,
								xUnit: "%",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-3-n-6",
							actionTypeId: "TRANSFORM_SCALE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 800,
								target: {},
								xValue: 1,
								yValue: 1,
								locked: !0,
							},
						},
						{
							id: "a-3-n-5",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 500,
								target: {},
								value: 1,
								unit: "",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x17ed94ef671,
		},
		"a-5": {
			id: "a-5",
			title: "investing",
			continuousParameterGroups: [
				{
					id: "a-5-p",
					type: "SCROLL_PROGRESS",
					parameterLabel: "Scroll",
					continuousActionGroups: [
						{
							keyframe: 10,
							actionItems: [
								{
									id: "a-5-n",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {},
										yValue: -80,
										xUnit: "PX",
										yUnit: "px",
										zUnit: "PX",
									},
								},
								{
									id: "a-5-n-7",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {},
										xValue: null,
										yValue: 80,
										xUnit: "px",
										yUnit: "px",
										zUnit: "PX",
									},
								},
							],
						},
						{
							keyframe: 90,
							actionItems: [
								{
									id: "a-5-n-2",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {},
										yValue: 80,
										xUnit: "PX",
										yUnit: "px",
										zUnit: "PX",
									},
								},
								{
									id: "a-5-n-8",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {},
										yValue: -80,
										xUnit: "PX",
										yUnit: "px",
										zUnit: "PX",
									},
								},
							],
						},
					],
				},
			],
			createdOn: 0x17ed965232e,
		},
		"a-2": {
			id: "a-2",
			title: "bg-circle",
			continuousParameterGroups: [
				{
					id: "a-2-p",
					type: "SCROLL_PROGRESS",
					parameterLabel: "Scroll",
					continuousActionGroups: [
						{
							keyframe: 0,
							actionItems: [
								{
									id: "a-2-n-14",
									actionTypeId: "STYLE_OPACITY",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											useEventTarget: "CHILDREN",
											selector: ".bg-circle",
											selectorGuids: ["f18a2a18-f03b-7ea7-874d-db56ae4027a6"],
										},
										value: 1,
										unit: "",
									},
								},
							],
						},
						{
							keyframe: 50,
							actionItems: [
								{
									id: "a-2-n",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											selector: ".bg-circle",
											selectorGuids: ["f18a2a18-f03b-7ea7-874d-db56ae4027a6"],
										},
										xValue: 0,
										yValue: 0,
										xUnit: "px",
										yUnit: "px",
										zUnit: "PX",
									},
								},
								{
									id: "a-2-n-3",
									actionTypeId: "TRANSFORM_SCALE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											selector: ".bg-circle",
											selectorGuids: ["f18a2a18-f03b-7ea7-874d-db56ae4027a6"],
										},
										xValue: 1,
										yValue: 1,
										locked: !0,
									},
								},
								{
									id: "a-2-n-11",
									actionTypeId: "STYLE_OPACITY",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											useEventTarget: "CHILDREN",
											selector: ".bg-circle",
											selectorGuids: ["f18a2a18-f03b-7ea7-874d-db56ae4027a6"],
										},
										value: 1,
										unit: "",
									},
								},
								{
									id: "a-2-n-13",
									actionTypeId: "STYLE_OPACITY",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											useEventTarget: "CHILDREN",
											selector: ".bg-circle",
											selectorGuids: ["f18a2a18-f03b-7ea7-874d-db56ae4027a6"],
										},
										value: 1,
										unit: "",
									},
								},
							],
						},
						{
							keyframe: 80,
							actionItems: [
								{
									id: "a-2-n-6",
									actionTypeId: "TRANSFORM_SCALE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											selector: ".bg-circle",
											selectorGuids: ["f18a2a18-f03b-7ea7-874d-db56ae4027a6"],
										},
										xValue: 0.3,
										yValue: 0.3,
										locked: !0,
									},
								},
								{
									id: "a-2-n-5",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											selector: ".bg-circle",
											selectorGuids: ["f18a2a18-f03b-7ea7-874d-db56ae4027a6"],
										},
										xValue: -865,
										yValue: 515,
										xUnit: "px",
										yUnit: "px",
										zUnit: "PX",
									},
								},
								{
									id: "a-2-n-12",
									actionTypeId: "STYLE_OPACITY",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											useEventTarget: "CHILDREN",
											selector: ".bg-circle",
											selectorGuids: ["f18a2a18-f03b-7ea7-874d-db56ae4027a6"],
										},
										value: 0.5,
										unit: "",
									},
								},
							],
						},
						{
							keyframe: 100,
							actionItems: [
								{
									id: "a-2-n-9",
									actionTypeId: "TRANSFORM_SCALE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											selector: ".bg-circle",
											selectorGuids: ["f18a2a18-f03b-7ea7-874d-db56ae4027a6"],
										},
										xValue: 1,
										yValue: 1,
										locked: !0,
									},
								},
								{
									id: "a-2-n-10",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											selector: ".bg-circle",
											selectorGuids: ["f18a2a18-f03b-7ea7-874d-db56ae4027a6"],
										},
										xValue: -305,
										yValue: 1140,
										xUnit: "px",
										yUnit: "px",
										zUnit: "PX",
									},
								},
							],
						},
					],
				},
			],
			createdOn: 0x17ed8d89482,
		},
		"a-22": {
			id: "a-22",
			title: "Scroll Right",
			continuousParameterGroups: [
				{
					id: "a-22-p",
					type: "SCROLL_PROGRESS",
					parameterLabel: "Scroll",
					continuousActionGroups: [
						{
							keyframe: 0,
							actionItems: [
								{
									id: "a-22-n",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {},
										xValue: 0,
										xUnit: "%",
										yUnit: "PX",
										zUnit: "PX",
									},
								},
							],
						},
						{
							keyframe: 100,
							actionItems: [
								{
									id: "a-22-n-2",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {},
										xValue: -66.6666666666,
										xUnit: "%",
										yUnit: "PX",
										zUnit: "PX",
									},
								},
							],
						},
					],
				},
			],
			createdOn: 0x17fa74a0f1e,
		},
		"a-23": {
			id: "a-23",
			title: "Scroll Right Tablet",
			continuousParameterGroups: [
				{
					id: "a-23-p",
					type: "SCROLL_PROGRESS",
					parameterLabel: "Scroll",
					continuousActionGroups: [
						{
							keyframe: 0,
							actionItems: [
								{
									id: "a-23-n",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {},
										xValue: 0,
										xUnit: "%",
										yUnit: "PX",
										zUnit: "PX",
									},
								},
							],
						},
						{
							keyframe: 100,
							actionItems: [
								{
									id: "a-23-n-2",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {},
										xValue: -83.3333,
										xUnit: "%",
										yUnit: "PX",
										zUnit: "PX",
									},
								},
							],
						},
					],
				},
			],
			createdOn: 0x17fa74a0f1e,
		},
		"a-26": {
			id: "a-26",
			title: "Template Card - Hover In",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-26-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								yValue: 0,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
						{
							id: "a-26-n-2",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								value: 1,
								unit: "",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-26-n-3",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "outQuart",
								duration: 400,
								target: {},
								yValue: -100,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
						{
							id: "a-26-n-4",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "outQuart",
								duration: 400,
								target: {},
								value: 0.75,
								unit: "",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x18c3b8c26cf,
		},
		"a-27": {
			id: "a-27",
			title: "Template Card - Hover Out",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-27-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "outQuart",
								duration: 400,
								target: {},
								yValue: 0,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
						{
							id: "a-27-n-2",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "outQuart",
								duration: 400,
								target: {},
								value: 1,
								unit: "",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !1,
			createdOn: 0x18c3b8c26cf,
		},
		"a-28": {
			id: "a-28",
			title: "Footer - Scroll Into View",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-28-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								yValue: 100,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-28-n-2",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "outQuart",
								duration: 400,
								target: {},
								yValue: 0,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x18c3bc8be3c,
		},
		"a-29": {
			id: "a-29",
			title: "Footer - Scroll Out Of View",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-29-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "outQuart",
								duration: 400,
								target: {},
								yValue: 100,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !1,
			createdOn: 0x18c3bcb76fd,
		},
		"a-36": {
			id: "a-36",
			title: "☝️ Slide To Top - 0.2s",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-36-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: !0,
									id: "6619473bef0f87a57128bd31|19c94f36-99cc-86cb-a434-773f47539250",
								},
								yValue: 10,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
						{
							id: "a-36-n-2",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: !0,
									id: "6619473bef0f87a57128bd31|19c94f36-99cc-86cb-a434-773f47539250",
								},
								value: 0,
								unit: "",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-36-n-3",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 200,
								easing: "ease",
								duration: 500,
								target: {
									useEventTarget: !0,
									id: "6619473bef0f87a57128bd31|19c94f36-99cc-86cb-a434-773f47539250",
								},
								yValue: 0,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
						{
							id: "a-36-n-4",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 200,
								easing: "ease",
								duration: 500,
								target: {
									useEventTarget: !0,
									id: "6619473bef0f87a57128bd31|19c94f36-99cc-86cb-a434-773f47539250",
								},
								value: 1,
								unit: "",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x17d2bd5e352,
		},
		"a-35": {
			id: "a-35",
			title: "Logo Strip V6 Animations",
			continuousParameterGroups: [
				{
					id: "a-35-p",
					type: "SCROLL_PROGRESS",
					parameterLabel: "Scroll",
					continuousActionGroups: [
						{
							keyframe: 18,
							actionItems: [
								{
									id: "a-35-n",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "ease",
										duration: 500,
										target: {
											id: "673ee4cd088aa593ca5ba409|054d9b51-e7c6-0f28-cb92-fa3e248c1047",
										},
										xValue: 4,
										yValue: null,
										xUnit: "%",
										yUnit: "px",
										zUnit: "PX",
									},
								},
								{
									id: "a-35-n-2",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "ease",
										duration: 500,
										target: {
											id: "673ee4cd088aa593ca5ba409|054d9b51-e7c6-0f28-cb92-fa3e248c1054",
										},
										xValue: -4,
										xUnit: "%",
										yUnit: "PX",
										zUnit: "PX",
									},
								},
							],
						},
						{
							keyframe: 44,
							actionItems: [
								{
									id: "a-35-n-4",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											id: "673ee4cd088aa593ca5ba409|054d9b51-e7c6-0f28-cb92-fa3e248c1054",
										},
										xValue: 4,
										xUnit: "%",
										yUnit: "PX",
										zUnit: "PX",
									},
								},
							],
						},
						{
							keyframe: 70,
							actionItems: [
								{
									id: "a-35-n-3",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											id: "673ee4cd088aa593ca5ba409|054d9b51-e7c6-0f28-cb92-fa3e248c1047",
										},
										xValue: -4,
										xUnit: "%",
										yUnit: "PX",
										zUnit: "PX",
									},
								},
							],
						},
					],
				},
			],
			createdOn: 0x17d36c86523,
		},
		"a-41": {
			id: "a-41",
			title: "Underline grow on hover",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-41-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".hover-line-fill",
									selectorGuids: ["42249667-b91b-fdc2-0317-533010d65bb4"],
								},
								xValue: -101,
								xUnit: "%",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-41-n-2",
							actionTypeId: "GENERAL_DISPLAY",
							config: {
								delay: 0,
								easing: "",
								duration: 0,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".hover-line-fill",
									selectorGuids: ["42249667-b91b-fdc2-0317-533010d65bb4"],
								},
								value: "flex",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-41-n-3",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "inOutQuint",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".hover-line-fill",
									selectorGuids: ["42249667-b91b-fdc2-0317-533010d65bb4"],
								},
								xValue: 0,
								xUnit: "%",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x17967b60033,
		},
		"a-42": {
			id: "a-42",
			title: "Underline shrink hover out",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-42-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "inOutQuint",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".hover-line-fill",
									selectorGuids: ["42249667-b91b-fdc2-0317-533010d65bb4"],
								},
								xValue: 101,
								xUnit: "%",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-42-n-2",
							actionTypeId: "GENERAL_DISPLAY",
							config: {
								delay: 0,
								easing: "",
								duration: 0,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".hover-line-fill",
									selectorGuids: ["42249667-b91b-fdc2-0317-533010d65bb4"],
								},
								value: "none",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-42-n-3",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 0,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".hover-line-fill",
									selectorGuids: ["42249667-b91b-fdc2-0317-533010d65bb4"],
								},
								xValue: -101,
								xUnit: "%",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !1,
			createdOn: 0x17967b60033,
		},
		"a-45": {
			id: "a-45",
			title: "nav desktop",
			continuousParameterGroups: [
				{
					id: "a-45-p",
					type: "SCROLL_PROGRESS",
					parameterLabel: "Scroll",
					continuousActionGroups: [
						{
							keyframe: 0,
							actionItems: [
								{
									id: "a-45-n",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: { id: "49074ee8-d6e9-617e-8005-9df85d8ca5dd" },
										yValue: 0,
										xUnit: "PX",
										yUnit: "%",
										zUnit: "PX",
									},
								},
								{
									id: "a-45-n-2",
									actionTypeId: "STYLE_OPACITY",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: { id: "49074ee8-d6e9-617e-8005-9df85d8ca5dd" },
										value: 0,
										unit: "",
									},
								},
								{
									id: "a-45-n-7",
									actionTypeId: "STYLE_TEXT_COLOR",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											selector: ".button-link.nav",
											selectorGuids: [
												"42249667-b91b-fdc2-0317-533010d65bb2",
												"e0e42920-8982-bbd2-09d5-9a89544ba7a8",
											],
										},
										globalSwatchId: "--white",
										rValue: 255,
										bValue: 255,
										gValue: 255,
										aValue: 1,
									},
								},
								{
									id: "a-45-n-9",
									actionTypeId: "STYLE_OPACITY",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: { id: "d17ecba8-4c00-b14a-ae3f-2c68d7ae0603" },
										value: 1,
										unit: "",
									},
								},
								{
									id: "a-45-n-11",
									actionTypeId: "STYLE_OPACITY",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: { id: "92eaa664-8693-4b83-e6f1-7ea8166418c4" },
										value: 0,
										unit: "",
									},
								},
								{
									id: "a-45-n-14",
									actionTypeId: "STYLE_BACKGROUND_COLOR",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											selector: ".hover-line-fill",
											selectorGuids: ["42249667-b91b-fdc2-0317-533010d65bb4"],
										},
										globalSwatchId: "--white",
										rValue: 255,
										bValue: 255,
										gValue: 255,
										aValue: 1,
									},
								},
							],
						},
						{
							keyframe: 4,
							actionItems: [
								{
									id: "a-45-n-3",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: { id: "49074ee8-d6e9-617e-8005-9df85d8ca5dd" },
										yValue: 100,
										xUnit: "PX",
										yUnit: "%",
										zUnit: "PX",
									},
								},
								{
									id: "a-45-n-4",
									actionTypeId: "STYLE_OPACITY",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: { id: "49074ee8-d6e9-617e-8005-9df85d8ca5dd" },
										value: 1,
										unit: "",
									},
								},
								{
									id: "a-45-n-8",
									actionTypeId: "STYLE_TEXT_COLOR",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											selector: ".button-link.nav",
											selectorGuids: [
												"42249667-b91b-fdc2-0317-533010d65bb2",
												"e0e42920-8982-bbd2-09d5-9a89544ba7a8",
											],
										},
										globalSwatchId: "--black",
										rValue: 24,
										bValue: 37,
										gValue: 31,
										aValue: 1,
									},
								},
								{
									id: "a-45-n-10",
									actionTypeId: "STYLE_OPACITY",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: { id: "d17ecba8-4c00-b14a-ae3f-2c68d7ae0603" },
										value: 0,
										unit: "",
									},
								},
								{
									id: "a-45-n-12",
									actionTypeId: "STYLE_OPACITY",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: { id: "92eaa664-8693-4b83-e6f1-7ea8166418c4" },
										value: 1,
										unit: "",
									},
								},
								{
									id: "a-45-n-13",
									actionTypeId: "STYLE_BACKGROUND_COLOR",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											selector: ".hover-line-fill",
											selectorGuids: ["42249667-b91b-fdc2-0317-533010d65bb4"],
										},
										globalSwatchId: "--black",
										rValue: 24,
										bValue: 37,
										gValue: 31,
										aValue: 1,
									},
								},
							],
						},
						{
							keyframe: 79,
							actionItems: [
								{
									id: "a-45-n-16",
									actionTypeId: "STYLE_BACKGROUND_COLOR",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											selector: ".hover-line-fill",
											selectorGuids: ["42249667-b91b-fdc2-0317-533010d65bb4"],
										},
										globalSwatchId: "--dark-slate-grey",
										rValue: 0,
										bValue: 70,
										gValue: 20,
										aValue: 1,
									},
								},
							],
						},
						{
							keyframe: 80,
							actionItems: [
								{
									id: "a-45-n-15",
									actionTypeId: "STYLE_BACKGROUND_COLOR",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											selector: ".hover-line-fill",
											selectorGuids: ["42249667-b91b-fdc2-0317-533010d65bb4"],
										},
										globalSwatchId: "--white",
										rValue: 255,
										bValue: 255,
										gValue: 255,
										aValue: 1,
									},
								},
							],
						},
						{
							keyframe: 96,
							actionItems: [
								{
									id: "a-45-n-19",
									actionTypeId: "STYLE_BACKGROUND_COLOR",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											selector: ".hover-line-fill",
											selectorGuids: ["42249667-b91b-fdc2-0317-533010d65bb4"],
										},
										globalSwatchId: "--white",
										rValue: 255,
										bValue: 255,
										gValue: 255,
										aValue: 1,
									},
								},
							],
						},
						{
							keyframe: 97,
							actionItems: [
								{
									id: "a-45-n-17",
									actionTypeId: "STYLE_BACKGROUND_COLOR",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											selector: ".hover-line-fill",
											selectorGuids: ["42249667-b91b-fdc2-0317-533010d65bb4"],
										},
										globalSwatchId: "--dark-slate-grey",
										rValue: 0,
										bValue: 70,
										gValue: 20,
										aValue: 1,
									},
								},
							],
						},
						{
							keyframe: 100,
							actionItems: [
								{
									id: "a-45-n-18",
									actionTypeId: "STYLE_BACKGROUND_COLOR",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											selector: ".hover-line-fill",
											selectorGuids: ["42249667-b91b-fdc2-0317-533010d65bb4"],
										},
										globalSwatchId: "--dark-slate-grey",
										rValue: 0,
										bValue: 70,
										gValue: 20,
										aValue: 1,
									},
								},
							],
						},
					],
				},
			],
			createdOn: 0x18d8d15ef1d,
		},
		"a-49": {
			id: "a-49",
			title: "Heading reveal",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-49-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: [0.86, 0, 0.07, 1],
								duration: 2500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".invest__heading",
									selectorGuids: ["75bda98f-178c-a96b-6a86-d9cfb04fe8a6"],
								},
								yValue: 120,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
						{
							id: "a-49-n-2",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".invest__heading",
									selectorGuids: ["75bda98f-178c-a96b-6a86-d9cfb04fe8a6"],
								},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-49-n-3",
							actionTypeId: "TRANSFORM_SKEW",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".invest__heading",
									selectorGuids: ["75bda98f-178c-a96b-6a86-d9cfb04fe8a6"],
								},
								yValue: 5,
								xUnit: "DEG",
								yUnit: "deg",
								zUnit: "DEG",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-49-n-15",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 500,
								easing: [0.23, 1, 0.32, 1],
								duration: 2500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".invest__heading",
									selectorGuids: ["75bda98f-178c-a96b-6a86-d9cfb04fe8a6"],
								},
								yValue: 0,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
						{
							id: "a-49-n-16",
							actionTypeId: "TRANSFORM_SKEW",
							config: {
								delay: 500,
								easing: [0.23, 1, 0.32, 1],
								duration: 2500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".invest__heading",
									selectorGuids: ["75bda98f-178c-a96b-6a86-d9cfb04fe8a6"],
								},
								yValue: 0,
								xUnit: "DEG",
								yUnit: "deg",
								zUnit: "DEG",
							},
						},
						{
							id: "a-49-n-17",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 500,
								easing: [0.25, 0.25, 0.75, 0.75],
								duration: 2500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".invest__heading",
									selectorGuids: ["75bda98f-178c-a96b-6a86-d9cfb04fe8a6"],
								},
								value: 1,
								unit: "",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x186816cf83d,
		},
		"a-56": {
			id: "a-56",
			title: "Heading reveal 3",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-56-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: [0.86, 0, 0.07, 1],
								duration: 2500,
								target: {
									id: "673ee4cd088aa593ca5ba409|5ec595cb-1161-2586-d85b-175432359df4",
								},
								yValue: 120,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
						{
							id: "a-56-n-2",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									id: "673ee4cd088aa593ca5ba409|5ec595cb-1161-2586-d85b-175432359df4",
								},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-56-n-3",
							actionTypeId: "TRANSFORM_SKEW",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									id: "673ee4cd088aa593ca5ba409|5ec595cb-1161-2586-d85b-175432359df4",
								},
								yValue: 5,
								xUnit: "DEG",
								yUnit: "deg",
								zUnit: "DEG",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-56-n-4",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 500,
								easing: [0.23, 1, 0.32, 1],
								duration: 2500,
								target: {
									id: "673ee4cd088aa593ca5ba409|5ec595cb-1161-2586-d85b-175432359df4",
								},
								yValue: 0,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
						{
							id: "a-56-n-5",
							actionTypeId: "TRANSFORM_SKEW",
							config: {
								delay: 500,
								easing: [0.23, 1, 0.32, 1],
								duration: 2500,
								target: {
									id: "673ee4cd088aa593ca5ba409|5ec595cb-1161-2586-d85b-175432359df4",
								},
								yValue: 0,
								xUnit: "DEG",
								yUnit: "deg",
								zUnit: "DEG",
							},
						},
						{
							id: "a-56-n-6",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 500,
								easing: [0.25, 0.25, 0.75, 0.75],
								duration: 2500,
								target: {
									id: "673ee4cd088aa593ca5ba409|5ec595cb-1161-2586-d85b-175432359df4",
								},
								value: 1,
								unit: "",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x186816cf83d,
		},
		"a-57": {
			id: "a-57",
			title: "THS12 Bg Gradient Change",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-57-n",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: { id: "7b6d945a-0109-72f8-feba-d41885a2ac65" },
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-57-n-2",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: { id: "7b6d945a-0109-72f8-feba-d41885a2ac66" },
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-57-n-3",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: { id: "7b6d945a-0109-72f8-feba-d41885a2ac67" },
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-57-n-4",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: { id: "7b6d945a-0109-72f8-feba-d41885a2ac68" },
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-57-n-5",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: { id: "7b6d945a-0109-72f8-feba-d41885a2ac69" },
								value: 0,
								unit: "",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-57-n-6",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "easeInOut",
								duration: 4e3,
								target: { id: "7b6d945a-0109-72f8-feba-d41885a2ac65" },
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-57-n-7",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "easeInOut",
								duration: 4e3,
								target: { id: "7b6d945a-0109-72f8-feba-d41885a2ac66" },
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-57-n-8",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "easeInOut",
								duration: 4e3,
								target: { id: "7b6d945a-0109-72f8-feba-d41885a2ac67" },
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-57-n-9",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "easeInOut",
								duration: 4e3,
								target: { id: "7b6d945a-0109-72f8-feba-d41885a2ac68" },
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-57-n-10",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "easeInOut",
								duration: 4e3,
								target: { id: "7b6d945a-0109-72f8-feba-d41885a2ac69" },
								value: 0,
								unit: "",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-57-n-11",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "easeInOut",
								duration: 4e3,
								target: { id: "7b6d945a-0109-72f8-feba-d41885a2ac65" },
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-57-n-12",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "easeInOut",
								duration: 4e3,
								target: { id: "7b6d945a-0109-72f8-feba-d41885a2ac66" },
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-57-n-13",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "easeInOut",
								duration: 4e3,
								target: { id: "7b6d945a-0109-72f8-feba-d41885a2ac67" },
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-57-n-14",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "easeInOut",
								duration: 4e3,
								target: { id: "7b6d945a-0109-72f8-feba-d41885a2ac68" },
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-57-n-15",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "easeInOut",
								duration: 4e3,
								target: { id: "7b6d945a-0109-72f8-feba-d41885a2ac69" },
								value: 0,
								unit: "",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-57-n-16",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "easeInOut",
								duration: 4e3,
								target: { id: "7b6d945a-0109-72f8-feba-d41885a2ac65" },
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-57-n-17",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "easeInOut",
								duration: 4e3,
								target: { id: "7b6d945a-0109-72f8-feba-d41885a2ac66" },
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-57-n-18",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "easeInOut",
								duration: 4e3,
								target: { id: "7b6d945a-0109-72f8-feba-d41885a2ac67" },
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-57-n-19",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "easeInOut",
								duration: 4e3,
								target: { id: "7b6d945a-0109-72f8-feba-d41885a2ac68" },
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-57-n-20",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "easeInOut",
								duration: 4e3,
								target: { id: "7b6d945a-0109-72f8-feba-d41885a2ac69" },
								value: 0,
								unit: "",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-57-n-21",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "easeInOut",
								duration: 4e3,
								target: { id: "7b6d945a-0109-72f8-feba-d41885a2ac65" },
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-57-n-22",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "easeInOut",
								duration: 4e3,
								target: { id: "7b6d945a-0109-72f8-feba-d41885a2ac66" },
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-57-n-23",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "easeInOut",
								duration: 4e3,
								target: { id: "7b6d945a-0109-72f8-feba-d41885a2ac67" },
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-57-n-24",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "easeInOut",
								duration: 4e3,
								target: { id: "7b6d945a-0109-72f8-feba-d41885a2ac68" },
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-57-n-25",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "easeInOut",
								duration: 4e3,
								target: { id: "7b6d945a-0109-72f8-feba-d41885a2ac69" },
								value: 1,
								unit: "",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-57-n-26",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "easeInOut",
								duration: 4e3,
								target: { id: "7b6d945a-0109-72f8-feba-d41885a2ac65" },
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-57-n-27",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "easeInOut",
								duration: 4e3,
								target: { id: "7b6d945a-0109-72f8-feba-d41885a2ac66" },
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-57-n-28",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "easeInOut",
								duration: 4e3,
								target: { id: "7b6d945a-0109-72f8-feba-d41885a2ac67" },
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-57-n-29",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "easeInOut",
								duration: 4e3,
								target: { id: "7b6d945a-0109-72f8-feba-d41885a2ac68" },
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-57-n-30",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "easeInOut",
								duration: 4e3,
								target: { id: "7b6d945a-0109-72f8-feba-d41885a2ac69" },
								value: 0,
								unit: "",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x180988ba0bc,
		},
		"a-59": {
			id: "a-59",
			title: "Button mouse over",
			continuousParameterGroups: [
				{
					id: "a-59-p",
					type: "MOUSE_X",
					parameterLabel: "Mouse X",
					continuousActionGroups: [
						{
							keyframe: 0,
							actionItems: [
								{
									id: "a-59-n",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {},
										xValue: -6,
										yValue: null,
										xUnit: "px",
										yUnit: "px",
										zUnit: "PX",
									},
								},
							],
						},
						{
							keyframe: 100,
							actionItems: [
								{
									id: "a-59-n-2",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {},
										xValue: 6,
										yValue: null,
										xUnit: "px",
										yUnit: "px",
										zUnit: "PX",
									},
								},
							],
						},
					],
				},
				{
					id: "a-59-p-2",
					type: "MOUSE_Y",
					parameterLabel: "Mouse Y",
					continuousActionGroups: [
						{
							keyframe: 0,
							actionItems: [
								{
									id: "a-59-n-3",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {},
										yValue: -6,
										xUnit: "PX",
										yUnit: "px",
										zUnit: "PX",
									},
								},
							],
						},
						{
							keyframe: 100,
							actionItems: [
								{
									id: "a-59-n-4",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {},
										yValue: 6,
										xUnit: "PX",
										yUnit: "px",
										zUnit: "PX",
									},
								},
							],
						},
					],
				},
			],
			createdOn: 0x1788f3f8594,
		},
		"a-63": {
			id: "a-63",
			title: "List item on hover",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-63-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								xValue: -18,
								xUnit: "px",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-63-n-2",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								xValue: 0,
								xUnit: "px",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-63-n-3",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								value: 0,
								unit: "",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-63-n-4",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "outQuad",
								duration: 600,
								target: {},
								xValue: 12,
								xUnit: "px",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-63-n-5",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 200,
								easing: "outQuad",
								duration: 400,
								target: {},
								xValue: 0,
								xUnit: "px",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-63-n-6",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 200,
								easing: "outQuad",
								duration: 400,
								target: {},
								value: 1,
								unit: "",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x1787bfe08c5,
		},
		"a-64": {
			id: "a-64",
			title: "List item hover out 2",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-64-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "outQuad",
								duration: 400,
								target: {},
								xValue: -18,
								xUnit: "px",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-64-n-2",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "outQuad",
								duration: 400,
								target: {},
								xValue: 0,
								xUnit: "px",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-64-n-3",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "outQuad",
								duration: 400,
								target: {},
								value: 0,
								unit: "",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !1,
			createdOn: 0x1787bfe08c5,
		},
		"a-67": {
			id: "a-67",
			title: "Membership tab change | circles",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-67-n",
							actionTypeId: "TRANSFORM_SCALE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								xValue: 0,
								yValue: 0,
								locked: !0,
							},
						},
						{
							id: "a-67-n-2",
							actionTypeId: "STYLE_BORDER",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								globalSwatchId: "dfc53acf",
								rValue: 230,
								bValue: 243,
								gValue: 230,
								aValue: 1,
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-67-n-3",
							actionTypeId: "TRANSFORM_SCALE",
							config: {
								delay: 0,
								easing: "outExpo",
								duration: 2e3,
								target: {},
								xValue: 1,
								yValue: 1,
								locked: !0,
							},
						},
						{
							id: "a-67-n-4",
							actionTypeId: "STYLE_BORDER",
							config: {
								delay: 0,
								easing: "outExpo",
								duration: 2e3,
								target: {},
								globalSwatchId: "654be6b4",
								rValue: 8,
								bValue: 58,
								gValue: 28,
								aValue: 1,
							},
						},
						{
							id: "a-67-n-5",
							actionTypeId: "STYLE_BACKGROUND_COLOR",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								globalSwatchId: "--dark-slate-grey",
								rValue: 0,
								bValue: 70,
								gValue: 20,
								aValue: 1,
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x178ae55e3d9,
		},
		"a-68": {
			id: "a-68",
			title: "Membership tab change out | circles",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-68-n",
							actionTypeId: "TRANSFORM_SCALE",
							config: {
								delay: 0,
								easing: "outExpo",
								duration: 1300,
								target: {},
								xValue: 0,
								yValue: 0,
								locked: !0,
							},
						},
						{
							id: "a-68-n-2",
							actionTypeId: "STYLE_BORDER",
							config: {
								delay: 0,
								easing: "outExpo",
								duration: 1300,
								target: {},
								globalSwatchId: "dfc53acf",
								rValue: 230,
								bValue: 243,
								gValue: 230,
								aValue: 1,
							},
						},
						{
							id: "a-68-n-3",
							actionTypeId: "STYLE_BACKGROUND_COLOR",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								globalSwatchId: "",
								rValue: 230,
								bValue: 243,
								gValue: 230,
								aValue: 1,
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !1,
			createdOn: 0x178ae55e3d9,
		},
		"a-73": {
			id: "a-73",
			title: "(NAVIGATION) Dropdown [open]",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-73-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".navigation-dropdown",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad195"],
								},
								yValue: -6,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-73-n-2",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".navigation-dropdown",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad195"],
								},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-73-n-3",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".dropdown-link-1",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad192"],
								},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-73-n-4",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".dropdown-link-1",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad192"],
								},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-73-n-5",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-73-n-6",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-73-n-7",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-73-n-8",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-73-n-9",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-73-n-10",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-73-n-11",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-73-n-12",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-73-n-13",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								xValue: -4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-73-n-14",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								xValue: -4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-73-n-15",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 300,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-73-n-16",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								value: 0,
								unit: "",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-73-n-17",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "easeOut",
								duration: 300,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".navigation-dropdown",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad195"],
								},
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-73-n-18",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 300,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".navigation-dropdown",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad195"],
								},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-73-n-19",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 200,
								easing: "ease",
								duration: 300,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".dropdown-link-1",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad192"],
								},
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-73-n-20",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 200,
								easing: "ease",
								duration: 300,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".dropdown-link-1",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad192"],
								},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-73-n-21",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 300,
								easing: "ease",
								duration: 300,
								target: {},
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-73-n-22",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 300,
								easing: "ease",
								duration: 300,
								target: {},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-73-n-23",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 300,
								easing: "ease",
								duration: 400,
								target: {},
								xValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-73-n-24",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 300,
								easing: "ease",
								duration: 400,
								target: {},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-73-n-25",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 400,
								easing: "ease",
								duration: 300,
								target: {},
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-73-n-26",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 400,
								easing: "ease",
								duration: 300,
								target: {},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-73-n-27",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 400,
								easing: "ease",
								duration: 400,
								target: {},
								xValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-73-n-28",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 400,
								easing: "ease",
								duration: 400,
								target: {},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-73-n-29",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 500,
								easing: "ease",
								duration: 300,
								target: {},
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-73-n-30",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 500,
								easing: "ease",
								duration: 300,
								target: {},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-73-n-31",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 600,
								easing: "ease",
								duration: 300,
								target: {},
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-73-n-32",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 600,
								easing: "ease",
								duration: 300,
								target: {},
								value: 1,
								unit: "",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x16f209c5d5b,
		},
		"a-74": {
			id: "a-74",
			title: "(NAVIGATION) Dropdown [close]",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-74-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".navigation-dropdown",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad195"],
								},
								yValue: -6,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-74-n-2",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".navigation-dropdown",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad195"],
								},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-74-n-3",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".dropdown-link-1",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad192"],
								},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-74-n-4",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".dropdown-link-1",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad192"],
								},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-74-n-5",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-74-n-6",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-74-n-7",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-74-n-8",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-74-n-9",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-74-n-10",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-74-n-11",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-74-n-12",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-74-n-13",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 0,
								target: {},
								xValue: -4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-74-n-14",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 0,
								target: {},
								xValue: -4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-74-n-15",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 0,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-74-n-16",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 0,
								target: {},
								value: 0,
								unit: "",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !1,
			createdOn: 0x16f209c5d5b,
		},
		"a-77": {
			id: "a-77",
			title: "search bar open 2",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-77-n",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									selector: ".searchbox-webflow",
									selectorGuids: ["1855b193-7f44-b6d1-444c-b7906ad4fd17"],
								},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-77-n-2",
							actionTypeId: "GENERAL_DISPLAY",
							config: {
								delay: 0,
								easing: "",
								duration: 0,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".exit-cb-wrapper-webflow",
									selectorGuids: ["b2c44df7-4425-c5bd-4d7f-6539bdfd6ee7"],
								},
								value: "none",
							},
						},
						{
							id: "a-77-n-3",
							actionTypeId: "GENERAL_DISPLAY",
							config: {
								delay: 0,
								easing: "",
								duration: 0,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".search-cb-wrapper-webflow",
									selectorGuids: ["b2c44df7-4425-c5bd-4d7f-6539bdfd6ee5"],
								},
								value: "flex",
							},
						},
						{
							id: "a-77-n-4",
							actionTypeId: "STYLE_SIZE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									selector: ".searchbox-webflow",
									selectorGuids: ["1855b193-7f44-b6d1-444c-b7906ad4fd17"],
								},
								heightValue: 0,
								widthUnit: "PX",
								heightUnit: "px",
								locked: !1,
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-77-n-5",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									selector: ".searchbox-webflow",
									selectorGuids: ["1855b193-7f44-b6d1-444c-b7906ad4fd17"],
								},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-77-n-6",
							actionTypeId: "STYLE_SIZE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									selector: ".searchbox-webflow",
									selectorGuids: ["1855b193-7f44-b6d1-444c-b7906ad4fd17"],
								},
								widthUnit: "PX",
								heightUnit: "AUTO",
								locked: !1,
							},
						},
						{
							id: "a-77-n-7",
							actionTypeId: "GENERAL_DISPLAY",
							config: {
								delay: 0,
								easing: "",
								duration: 0,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".search-cb-wrapper-webflow",
									selectorGuids: ["b2c44df7-4425-c5bd-4d7f-6539bdfd6ee5"],
								},
								value: "none",
							},
						},
						{
							id: "a-77-n-8",
							actionTypeId: "GENERAL_DISPLAY",
							config: {
								delay: 0,
								easing: "",
								duration: 0,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".exit-cb-wrapper-webflow",
									selectorGuids: ["b2c44df7-4425-c5bd-4d7f-6539bdfd6ee7"],
								},
								value: "flex",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x18d8d1aff2c,
		},
		"a-78": {
			id: "a-78",
			title: "search bar close 2",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-78-n",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									selector: ".searchbox-webflow",
									selectorGuids: ["1855b193-7f44-b6d1-444c-b7906ad4fd17"],
								},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-78-n-2",
							actionTypeId: "STYLE_SIZE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									selector: ".searchbox-webflow",
									selectorGuids: ["1855b193-7f44-b6d1-444c-b7906ad4fd17"],
								},
								heightValue: 0,
								widthUnit: "PX",
								heightUnit: "px",
								locked: !1,
							},
						},
						{
							id: "a-78-n-3",
							actionTypeId: "GENERAL_DISPLAY",
							config: {
								delay: 0,
								easing: "",
								duration: 0,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".exit-cb-wrapper-webflow",
									selectorGuids: ["b2c44df7-4425-c5bd-4d7f-6539bdfd6ee7"],
								},
								value: "none",
							},
						},
						{
							id: "a-78-n-4",
							actionTypeId: "GENERAL_DISPLAY",
							config: {
								delay: 0,
								easing: "",
								duration: 0,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".search-cb-wrapper-webflow",
									selectorGuids: ["b2c44df7-4425-c5bd-4d7f-6539bdfd6ee5"],
								},
								value: "flex",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !1,
			createdOn: 0x18d8d1aff2c,
		},
		"a-84": {
			id: "a-84",
			title: "Card Close",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-84-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "outQuart",
								duration: 800,
								target: {},
								yValue: 0,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
						{
							id: "a-84-n-2",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "outQuart",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".card-heading-background",
									selectorGuids: ["3adc79b6-1722-c9a1-25b7-df2acd4a7db0"],
								},
								xValue: -100.5,
								xUnit: "%",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-84-n-3",
							actionTypeId: "TRANSFORM_SCALE",
							config: {
								delay: 0,
								easing: "outQuart",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".card-image",
									selectorGuids: ["3adc79b6-1722-c9a1-25b7-df2acd4a7da9"],
								},
								xValue: 1,
								yValue: 1,
								locked: !0,
							},
						},
						{
							id: "a-84-n-4",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 800,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".grid-item-overlay",
									selectorGuids: ["3adc79b6-1722-c9a1-25b7-df2acd4a7dac"],
								},
								value: 1,
								unit: "",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !1,
			createdOn: 0x179c4df7693,
		},
		"a-83": {
			id: "a-83",
			title: "Card Open",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-83-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".card-heading-background",
									selectorGuids: ["3adc79b6-1722-c9a1-25b7-df2acd4a7db0"],
								},
								xValue: -100.5,
								xUnit: "%",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-83-n-2",
							actionTypeId: "TRANSFORM_SCALE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".card-image",
									selectorGuids: ["3adc79b6-1722-c9a1-25b7-df2acd4a7da9"],
								},
								xValue: 1,
								yValue: 1,
								locked: !0,
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-83-n-3",
							actionTypeId: "GENERAL_DISPLAY",
							config: {
								delay: 0,
								easing: "",
								duration: 0,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".card-heading-background",
									selectorGuids: ["3adc79b6-1722-c9a1-25b7-df2acd4a7db0"],
								},
								value: "block",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-83-n-4",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "outQuart",
								duration: 800,
								target: {},
								yValue: -60,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
						{
							id: "a-83-n-5",
							actionTypeId: "TRANSFORM_SCALE",
							config: {
								delay: 0,
								easing: "outQuart",
								duration: 1e3,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".card-image",
									selectorGuids: ["3adc79b6-1722-c9a1-25b7-df2acd4a7da9"],
								},
								xValue: 1.1,
								yValue: 1.1,
								locked: !0,
							},
						},
						{
							id: "a-83-n-6",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "outQuart",
								duration: 600,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".card-heading-background",
									selectorGuids: ["3adc79b6-1722-c9a1-25b7-df2acd4a7db0"],
								},
								xValue: 0,
								xUnit: "%",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-83-n-7",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 1e3,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".grid-item-overlay",
									selectorGuids: ["3adc79b6-1722-c9a1-25b7-df2acd4a7dac"],
								},
								value: 0,
								unit: "",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x179c4debf09,
		},
		"a-85": {
			id: "a-85",
			title: "Portfolio Hover",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-85-n-3",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "inOutQuad",
								duration: 300,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".image-33",
									selectorGuids: ["253c50f2-83d2-e689-c6ab-ce22316f5dd5"],
								},
								xValue: -8,
								xUnit: "%",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-85-n-2",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "inOutQuad",
								duration: 300,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".image-35",
									selectorGuids: ["6306e7d5-206e-fef3-9fa2-30f6f3f4961c"],
								},
								xValue: 43,
								yValue: -16,
								xUnit: "%",
								yUnit: "px",
								zUnit: "PX",
							},
						},
						{
							id: "a-85-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "inOutQuad",
								duration: 300,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".image-34",
									selectorGuids: ["afcc6078-aea6-a02a-69f9-6200c726fd9b"],
								},
								xValue: -113,
								yValue: -16,
								xUnit: "%",
								yUnit: "px",
								zUnit: "PX",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !1,
			createdOn: 0x18f39cd97d1,
		},
		"a-86": {
			id: "a-86",
			title: "Portfolio Hover out",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-86-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".image-34",
									selectorGuids: ["afcc6078-aea6-a02a-69f9-6200c726fd9b"],
								},
								xValue: null,
								yValue: 0,
								xUnit: "px",
								yUnit: "px",
								zUnit: "PX",
							},
						},
						{
							id: "a-86-n-5",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".image-33",
									selectorGuids: ["253c50f2-83d2-e689-c6ab-ce22316f5dd5"],
								},
								xValue: 0,
								xUnit: "px",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-86-n-2",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".image-35",
									selectorGuids: ["6306e7d5-206e-fef3-9fa2-30f6f3f4961c"],
								},
								xValue: null,
								yValue: 0,
								xUnit: "px",
								yUnit: "px",
								zUnit: "PX",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-86-n-3",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "inOutQuad",
								duration: 300,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".image-34",
									selectorGuids: ["afcc6078-aea6-a02a-69f9-6200c726fd9b"],
								},
								xValue: 0,
								yValue: 0,
								xUnit: "%",
								yUnit: "px",
								zUnit: "PX",
							},
						},
						{
							id: "a-86-n-6",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "inOutQuad",
								duration: 300,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".image-33",
									selectorGuids: ["253c50f2-83d2-e689-c6ab-ce22316f5dd5"],
								},
								xValue: 0,
								xUnit: "%",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-86-n-4",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "inOutQuad",
								duration: 300,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".image-35",
									selectorGuids: ["6306e7d5-206e-fef3-9fa2-30f6f3f4961c"],
								},
								xValue: 0,
								yValue: 0,
								xUnit: "%",
								yUnit: "px",
								zUnit: "PX",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x18f39cd97d1,
		},
		"a-91": {
			id: "a-91",
			title: "Big CTA Arrow",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-91-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: [0.215, 0.61, 0.355, 1],
								duration: 200,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".icon-embed-xlarge.absolute",
									selectorGuids: [
										"2670647e-1ff2-df09-d326-b0249ef17600",
										"2670647e-1ff2-df09-d326-b0249ef17609",
									],
								},
								xValue: 0.5,
								yValue: -0.5,
								xUnit: "rem",
								yUnit: "rem",
								zUnit: "PX",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !1,
			createdOn: 0x18694e1199a,
		},
		"a-87": {
			id: "a-87",
			title: "Project Info In",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-87-n",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".project_content-wrapper",
									selectorGuids: ["2670647e-1ff2-df09-d326-b0249ef175ff"],
								},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-87-n-2",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".project_info",
									selectorGuids: ["2670647e-1ff2-df09-d326-b0249ef17601"],
								},
								xValue: null,
								yValue: -2,
								xUnit: "px",
								yUnit: "rem",
								zUnit: "PX",
							},
						},
						{
							id: "a-87-n-3",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".project_date-and-link",
									selectorGuids: ["2670647e-1ff2-df09-d326-b0249ef175f1"],
								},
								yValue: 2,
								xUnit: "PX",
								yUnit: "rem",
								zUnit: "PX",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-87-n-4",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "easeOut",
								duration: 200,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".project_content-wrapper",
									selectorGuids: ["2670647e-1ff2-df09-d326-b0249ef175ff"],
								},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-87-n-5",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: [0.246, 0.557, 0.509, 0.966],
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".project_info",
									selectorGuids: ["2670647e-1ff2-df09-d326-b0249ef17601"],
								},
								yValue: 0,
								xUnit: "PX",
								yUnit: "rem",
								zUnit: "PX",
							},
						},
						{
							id: "a-87-n-6",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: [0.222, 0.627, 0.509, 0.954],
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".project_date-and-link",
									selectorGuids: ["2670647e-1ff2-df09-d326-b0249ef175f1"],
								},
								yValue: 0,
								xUnit: "PX",
								yUnit: "rem",
								zUnit: "PX",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x1868602f9b1,
		},
		"a-88": {
			id: "a-88",
			title: "Project Info Out",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-88-n",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: [0.165, 0.84, 0.44, 1],
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".project_content-wrapper",
									selectorGuids: ["2670647e-1ff2-df09-d326-b0249ef175ff"],
								},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-88-n-2",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: [0.165, 0.84, 0.44, 1],
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".project_date-and-link",
									selectorGuids: ["2670647e-1ff2-df09-d326-b0249ef175f1"],
								},
								yValue: 2,
								xUnit: "PX",
								yUnit: "rem",
								zUnit: "PX",
							},
						},
						{
							id: "a-88-n-3",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: [0.165, 0.84, 0.44, 1],
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".project_info",
									selectorGuids: ["2670647e-1ff2-df09-d326-b0249ef17601"],
								},
								xValue: null,
								yValue: -2,
								xUnit: "px",
								yUnit: "rem",
								zUnit: "PX",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !1,
			createdOn: 0x1868602f9b1,
		},
		"a-92": {
			id: "a-92",
			title: "Big CTA Arrow Out",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-92-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: [0.215, 0.61, 0.355, 1],
								duration: 200,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".icon-embed-xlarge.absolute",
									selectorGuids: [
										"2670647e-1ff2-df09-d326-b0249ef17600",
										"2670647e-1ff2-df09-d326-b0249ef17609",
									],
								},
								xValue: -0.5,
								yValue: 0.5,
								xUnit: "rem",
								yUnit: "rem",
								zUnit: "PX",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !1,
			createdOn: 0x18694e1199a,
		},
		"a-93": {
			id: "a-93",
			title: "close notification",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-93-n",
							actionTypeId: "GENERAL_DISPLAY",
							config: {
								delay: 100,
								easing: "",
								duration: 0,
								target: {
									useEventTarget: "PARENT",
									selector: ".notification-banner",
									selectorGuids: ["42c2f046-071a-1d67-84cf-5fc454b88946"],
								},
								value: "none",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !1,
			createdOn: 0x188fd31afa7,
		},
		"a-94": {
			id: "a-94",
			title: "3 Column Image Scroll Animation",
			continuousParameterGroups: [
				{
					id: "a-94-p",
					type: "SCROLL_PROGRESS",
					parameterLabel: "Scroll",
					continuousActionGroups: [
						{
							keyframe: 0,
							actionItems: [
								{
									id: "a-94-n",
									actionTypeId: "STYLE_SIZE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											useEventTarget: "CHILDREN",
											selector: ".column-scroll-sticky-inner",
											selectorGuids: ["d2a02493-3cc2-8a4e-2eb8-92a5f5f0dd37"],
										},
										widthValue: 100,
										heightValue: 100,
										widthUnit: "vw",
										heightUnit: "%",
										locked: !1,
									},
								},
							],
						},
						{
							keyframe: 1,
							actionItems: [
								{
									id: "a-94-n-2",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											useEventTarget: "CHILDREN",
											selector: ".column-scroll-item.is-1",
											selectorGuids: [
												"d2a02493-3cc2-8a4e-2eb8-92a5f5f0dd3e",
												"d2a02493-3cc2-8a4e-2eb8-92a5f5f0dd47",
											],
										},
										yValue: 100,
										xUnit: "PX",
										yUnit: "%",
										zUnit: "PX",
									},
								},
							],
						},
						{
							keyframe: 15,
							actionItems: [
								{
									id: "a-94-n-3",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											useEventTarget: "CHILDREN",
											selector: ".column-scroll-item.is-2",
											selectorGuids: [
												"d2a02493-3cc2-8a4e-2eb8-92a5f5f0dd3e",
												"d2a02493-3cc2-8a4e-2eb8-92a5f5f0dd49",
											],
										},
										yValue: 100,
										xUnit: "PX",
										yUnit: "%",
										zUnit: "PX",
									},
								},
							],
						},
						{
							keyframe: 23,
							actionItems: [
								{
									id: "a-94-n-4",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											useEventTarget: "CHILDREN",
											selector: ".column-scroll-item.is-3",
											selectorGuids: [
												"d2a02493-3cc2-8a4e-2eb8-92a5f5f0dd3e",
												"d2a02493-3cc2-8a4e-2eb8-92a5f5f0dd48",
											],
										},
										yValue: 100,
										xUnit: "PX",
										yUnit: "%",
										zUnit: "PX",
									},
								},
							],
						},
						{
							keyframe: 40,
							actionItems: [
								{
									id: "a-94-n-5",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											useEventTarget: "CHILDREN",
											selector: ".column-scroll-item.is-1",
											selectorGuids: [
												"d2a02493-3cc2-8a4e-2eb8-92a5f5f0dd3e",
												"d2a02493-3cc2-8a4e-2eb8-92a5f5f0dd47",
											],
										},
										yValue: 0,
										xUnit: "PX",
										yUnit: "%",
										zUnit: "PX",
									},
								},
								{
									id: "a-94-n-6",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											useEventTarget: "CHILDREN",
											selector: ".column-scroll-item.is-2",
											selectorGuids: [
												"d2a02493-3cc2-8a4e-2eb8-92a5f5f0dd3e",
												"d2a02493-3cc2-8a4e-2eb8-92a5f5f0dd49",
											],
										},
										yValue: 0,
										xUnit: "PX",
										yUnit: "%",
										zUnit: "PX",
									},
								},
								{
									id: "a-94-n-7",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											useEventTarget: "CHILDREN",
											selector: ".column-scroll-item.is-3",
											selectorGuids: [
												"d2a02493-3cc2-8a4e-2eb8-92a5f5f0dd3e",
												"d2a02493-3cc2-8a4e-2eb8-92a5f5f0dd48",
											],
										},
										yValue: 0,
										xUnit: "PX",
										yUnit: "%",
										zUnit: "PX",
									},
								},
								{
									id: "a-94-n-8",
									actionTypeId: "STYLE_SIZE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											useEventTarget: "CHILDREN",
											selector: ".column-scroll-sticky-inner",
											selectorGuids: ["d2a02493-3cc2-8a4e-2eb8-92a5f5f0dd37"],
										},
										widthValue: 100,
										heightValue: 100,
										widthUnit: "vw",
										heightUnit: "%",
										locked: !1,
									},
								},
							],
						},
						{
							keyframe: 63,
							actionItems: [
								{
									id: "a-94-n-9",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											selector: ".column-scroll-content-title.is-1",
											selectorGuids: [
												"d2a02493-3cc2-8a4e-2eb8-92a5f5f0dd3c",
												"d2a02493-3cc2-8a4e-2eb8-92a5f5f0dd44",
											],
										},
										yValue: 100,
										xUnit: "PX",
										yUnit: "%",
										zUnit: "PX",
									},
								},
								{
									id: "a-94-n-10",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											selector: ".column-scroll-content-title.is-2",
											selectorGuids: [
												"d2a02493-3cc2-8a4e-2eb8-92a5f5f0dd3c",
												"d2a02493-3cc2-8a4e-2eb8-92a5f5f0dd45",
											],
										},
										yValue: 100,
										xUnit: "PX",
										yUnit: "%",
										zUnit: "PX",
									},
								},
							],
						},
						{
							keyframe: 65,
							actionItems: [
								{
									id: "a-94-n-11",
									actionTypeId: "STYLE_OPACITY",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											selector: ".column-scroll-content-copy",
											selectorGuids: ["d2a02493-3cc2-8a4e-2eb8-92a5f5f0dd3b"],
										},
										value: 0,
										unit: "",
									},
								},
							],
						},
						{
							keyframe: 73,
							actionItems: [
								{
									id: "a-94-n-12",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											selector: ".column-scroll-content-title.is-1",
											selectorGuids: [
												"d2a02493-3cc2-8a4e-2eb8-92a5f5f0dd3c",
												"d2a02493-3cc2-8a4e-2eb8-92a5f5f0dd44",
											],
										},
										yValue: 0,
										xUnit: "PX",
										yUnit: "%",
										zUnit: "PX",
									},
								},
								{
									id: "a-94-n-13",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											selector: ".column-scroll-content-title.is-2",
											selectorGuids: [
												"d2a02493-3cc2-8a4e-2eb8-92a5f5f0dd3c",
												"d2a02493-3cc2-8a4e-2eb8-92a5f5f0dd45",
											],
										},
										yValue: 0,
										xUnit: "PX",
										yUnit: "%",
										zUnit: "PX",
									},
								},
							],
						},
						{
							keyframe: 74,
							actionItems: [
								{
									id: "a-94-n-14",
									actionTypeId: "STYLE_OPACITY",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											selector: ".column-scroll-content-copy",
											selectorGuids: ["d2a02493-3cc2-8a4e-2eb8-92a5f5f0dd3b"],
										},
										value: 1,
										unit: "",
									},
								},
							],
						},
						{
							keyframe: 78,
							actionItems: [
								{
									id: "a-94-n-15",
									actionTypeId: "STYLE_SIZE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											useEventTarget: "CHILDREN",
											selector: ".column-scroll-sticky-inner",
											selectorGuids: ["d2a02493-3cc2-8a4e-2eb8-92a5f5f0dd37"],
										},
										widthValue: 33.33,
										heightValue: 100,
										widthUnit: "vw",
										heightUnit: "%",
										locked: !1,
									},
								},
							],
						},
					],
				},
			],
			createdOn: 0x18e51fec48e,
		},
		"a-7": {
			id: "a-7",
			title: "circles",
			continuousParameterGroups: [
				{
					id: "a-7-p",
					type: "MOUSE_X",
					parameterLabel: "Mouse X",
					continuousActionGroups: [
						{
							keyframe: 0,
							actionItems: [
								{
									id: "a-7-n",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {},
										xValue: -15,
										xUnit: "px",
										yUnit: "PX",
										zUnit: "PX",
									},
								},
								{
									id: "a-7-n-5",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {},
										xValue: -25,
										xUnit: "px",
										yUnit: "PX",
										zUnit: "PX",
									},
								},
								{
									id: "a-7-n-9",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											useEventTarget: "CHILDREN",
											selector: ".hero__circle-dot",
											selectorGuids: ["219d0983-e5a4-d2ec-70c8-bbeb098d054c"],
										},
										xValue: -30,
										xUnit: "px",
										yUnit: "PX",
										zUnit: "PX",
									},
								},
								{
									id: "a-7-n-13",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											id: "673ee4cd088aa593ca5ba409|dcf5e822-841b-68f2-2ce7-08c4506c69c8",
										},
										xValue: 10,
										xUnit: "px",
										yUnit: "PX",
										zUnit: "PX",
									},
								},
							],
						},
						{
							keyframe: 100,
							actionItems: [
								{
									id: "a-7-n-2",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {},
										xValue: 15,
										xUnit: "px",
										yUnit: "PX",
										zUnit: "PX",
									},
								},
								{
									id: "a-7-n-6",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {},
										xValue: 25,
										xUnit: "px",
										yUnit: "PX",
										zUnit: "PX",
									},
								},
								{
									id: "a-7-n-10",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											useEventTarget: "CHILDREN",
											selector: ".hero__circle-dot",
											selectorGuids: ["219d0983-e5a4-d2ec-70c8-bbeb098d054c"],
										},
										xValue: 30,
										xUnit: "px",
										yUnit: "PX",
										zUnit: "PX",
									},
								},
								{
									id: "a-7-n-14",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											id: "673ee4cd088aa593ca5ba409|dcf5e822-841b-68f2-2ce7-08c4506c69c8",
										},
										xValue: -10,
										xUnit: "px",
										yUnit: "PX",
										zUnit: "PX",
									},
								},
							],
						},
					],
				},
				{
					id: "a-7-p-2",
					type: "MOUSE_Y",
					parameterLabel: "Mouse Y",
					continuousActionGroups: [
						{
							keyframe: 0,
							actionItems: [
								{
									id: "a-7-n-3",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {},
										yValue: 15,
										xUnit: "PX",
										yUnit: "px",
										zUnit: "PX",
									},
								},
								{
									id: "a-7-n-7",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {},
										yValue: 25,
										xUnit: "PX",
										yUnit: "px",
										zUnit: "PX",
									},
								},
								{
									id: "a-7-n-11",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											useEventTarget: "CHILDREN",
											selector: ".hero__circle-dot",
											selectorGuids: ["219d0983-e5a4-d2ec-70c8-bbeb098d054c"],
										},
										yValue: 30,
										xUnit: "PX",
										yUnit: "px",
										zUnit: "PX",
									},
								},
								{
									id: "a-7-n-15",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											id: "673ee4cd088aa593ca5ba409|dcf5e822-841b-68f2-2ce7-08c4506c69c8",
										},
										yValue: -10,
										xUnit: "PX",
										yUnit: "px",
										zUnit: "PX",
									},
								},
							],
						},
						{
							keyframe: 100,
							actionItems: [
								{
									id: "a-7-n-4",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {},
										yValue: -15,
										xUnit: "PX",
										yUnit: "px",
										zUnit: "PX",
									},
								},
								{
									id: "a-7-n-8",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {},
										yValue: -25,
										xUnit: "PX",
										yUnit: "px",
										zUnit: "PX",
									},
								},
								{
									id: "a-7-n-12",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											useEventTarget: "CHILDREN",
											selector: ".hero__circle-dot",
											selectorGuids: ["219d0983-e5a4-d2ec-70c8-bbeb098d054c"],
										},
										yValue: -30,
										xUnit: "PX",
										yUnit: "px",
										zUnit: "PX",
									},
								},
								{
									id: "a-7-n-16",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											id: "673ee4cd088aa593ca5ba409|dcf5e822-841b-68f2-2ce7-08c4506c69c8",
										},
										yValue: 10,
										xUnit: "PX",
										yUnit: "px",
										zUnit: "PX",
									},
								},
							],
						},
					],
				},
			],
			createdOn: 0x17eda12dc80,
		},
		"a-95": {
			id: "a-95",
			title: "hero-load",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-95-n",
							actionTypeId: "STYLE_SIZE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								widthValue: 0,
								widthUnit: "px",
								heightUnit: "PX",
								locked: !1,
							},
						},
						{
							id: "a-95-n-2",
							actionTypeId: "TRANSFORM_ROTATE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								zValue: -180,
								xUnit: "DEG",
								yUnit: "DEG",
								zUnit: "deg",
							},
						},
						{
							id: "a-95-n-3",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								yValue: -50,
								xUnit: "PX",
								yUnit: "px",
								zUnit: "PX",
							},
						},
						{
							id: "a-95-n-4",
							actionTypeId: "TRANSFORM_ROTATE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									selector: ".hero__elem-circle1.mod--2",
									selectorGuids: [
										"df65ad45-944c-e6f3-6144-a728ce64aec5",
										"df65ad45-944c-e6f3-6144-a728ce64aeca",
									],
								},
								zValue: 180,
								xUnit: "DEG",
								yUnit: "DEG",
								zUnit: "deg",
							},
						},
						{
							id: "a-95-n-5",
							actionTypeId: "TRANSFORM_ROTATE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									selector: ".hero__elem-circle1.mod--1",
									selectorGuids: [
										"df65ad45-944c-e6f3-6144-a728ce64aec5",
										"df65ad45-944c-e6f3-6144-a728ce64aec8",
									],
								},
								zValue: 180,
								xUnit: "DEG",
								yUnit: "DEG",
								zUnit: "deg",
							},
						},
						{
							id: "a-95-n-6",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								yValue: -50,
								xUnit: "PX",
								yUnit: "px",
								zUnit: "PX",
							},
						},
						{
							id: "a-95-n-7",
							actionTypeId: "TRANSFORM_ROTATE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								zValue: -180,
								xUnit: "DEG",
								yUnit: "DEG",
								zUnit: "deg",
							},
						},
						{
							id: "a-95-n-8",
							actionTypeId: "STYLE_SIZE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								widthValue: 0,
								widthUnit: "px",
								heightUnit: "PX",
								locked: !1,
							},
						},
						{
							id: "a-95-n-9",
							actionTypeId: "TRANSFORM_SCALE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								xValue: 0,
								yValue: 0,
								locked: !0,
							},
						},
						{
							id: "a-95-n-10",
							actionTypeId: "TRANSFORM_SCALE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								xValue: 0,
								yValue: 0,
								locked: !0,
							},
						},
						{
							id: "a-95-n-11",
							actionTypeId: "STYLE_SIZE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								heightValue: 0,
								widthUnit: "PX",
								heightUnit: "px",
								locked: !1,
							},
						},
						{
							id: "a-95-n-12",
							actionTypeId: "STYLE_SIZE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								heightValue: 0,
								widthUnit: "PX",
								heightUnit: "px",
								locked: !1,
							},
						},
						{
							id: "a-95-n-13",
							actionTypeId: "STYLE_SIZE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								widthValue: 0,
								widthUnit: "px",
								heightUnit: "PX",
								locked: !1,
							},
						},
						{
							id: "a-95-n-14",
							actionTypeId: "STYLE_SIZE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								heightValue: 0,
								widthUnit: "PX",
								heightUnit: "px",
								locked: !1,
							},
						},
						{
							id: "a-95-n-15",
							actionTypeId: "TRANSFORM_SCALE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								xValue: 0,
								yValue: 0,
								locked: !0,
							},
						},
						{
							id: "a-95-n-16",
							actionTypeId: "STYLE_SIZE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								widthValue: 0,
								widthUnit: "px",
								heightUnit: "PX",
								locked: !1,
							},
						},
						{
							id: "a-95-n-17",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-95-n-18",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-95-n-19",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								yValue: -30,
								xUnit: "PX",
								yUnit: "px",
								zUnit: "PX",
							},
						},
						{
							id: "a-95-n-20",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-95-n-21",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								xValue: -200,
								xUnit: "px",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-95-n-22",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-95-n-23",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								xValue: 100,
								xUnit: "px",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-95-n-24",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-95-n-25",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								xValue: -200,
								xUnit: "px",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-95-n-26",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-95-n-27",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								value: 0,
								unit: "",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-95-n-28",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 1e3,
								target: {},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-95-n-29",
							actionTypeId: "STYLE_SIZE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 1e3,
								target: {},
								widthUnit: "AUTO",
								heightUnit: "PX",
								locked: !1,
							},
						},
						{
							id: "a-95-n-30",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 1e3,
								target: {},
								xValue: 0,
								xUnit: "px",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-95-n-31",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 1e3,
								target: {},
								xValue: 0,
								xUnit: "px",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-95-n-32",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 1e3,
								target: {},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-95-n-33",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 1e3,
								target: {},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-95-n-34",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 1e3,
								target: {},
								xValue: 0,
								xUnit: "px",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-95-n-35",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 1e3,
								target: {},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-95-n-36",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 500,
								target: {},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-95-n-37",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 100,
								easing: "ease",
								duration: 800,
								target: {},
								yValue: 0,
								xUnit: "PX",
								yUnit: "px",
								zUnit: "PX",
							},
						},
						{
							id: "a-95-n-38",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 200,
								easing: "ease",
								duration: 500,
								target: {},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-95-n-39",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 200,
								easing: "ease",
								duration: 800,
								target: {},
								yValue: 0,
								xUnit: "PX",
								yUnit: "px",
								zUnit: "PX",
							},
						},
						{
							id: "a-95-n-40",
							actionTypeId: "TRANSFORM_ROTATE",
							config: {
								delay: 400,
								easing: "ease",
								duration: 1e3,
								target: {},
								zValue: 0,
								xUnit: "DEG",
								yUnit: "DEG",
								zUnit: "deg",
							},
						},
						{
							id: "a-95-n-41",
							actionTypeId: "TRANSFORM_ROTATE",
							config: {
								delay: 400,
								easing: "ease",
								duration: 1e3,
								target: {},
								zValue: 0,
								xUnit: "DEG",
								yUnit: "DEG",
								zUnit: "deg",
							},
						},
						{
							id: "a-95-n-42",
							actionTypeId: "TRANSFORM_ROTATE",
							config: {
								delay: 400,
								easing: "ease",
								duration: 1e3,
								target: {
									selector: ".hero__elem-circle1.mod--2",
									selectorGuids: [
										"df65ad45-944c-e6f3-6144-a728ce64aec5",
										"df65ad45-944c-e6f3-6144-a728ce64aeca",
									],
								},
								zValue: 0,
								xUnit: "DEG",
								yUnit: "DEG",
								zUnit: "deg",
							},
						},
						{
							id: "a-95-n-43",
							actionTypeId: "TRANSFORM_ROTATE",
							config: {
								delay: 400,
								easing: "ease",
								duration: 1e3,
								target: {
									selector: ".hero__elem-circle1.mod--1",
									selectorGuids: [
										"df65ad45-944c-e6f3-6144-a728ce64aec5",
										"df65ad45-944c-e6f3-6144-a728ce64aec8",
									],
								},
								zValue: 0,
								xUnit: "DEG",
								yUnit: "DEG",
								zUnit: "deg",
							},
						},
						{
							id: "a-95-n-44",
							actionTypeId: "STYLE_SIZE",
							config: {
								delay: 400,
								easing: "ease",
								duration: 1e3,
								target: {},
								widthUnit: "AUTO",
								heightUnit: "PX",
								locked: !1,
							},
						},
						{
							id: "a-95-n-45",
							actionTypeId: "TRANSFORM_SCALE",
							config: {
								delay: 400,
								easing: "ease",
								duration: 1e3,
								target: {},
								xValue: 1,
								yValue: 1,
								locked: !0,
							},
						},
						{
							id: "a-95-n-46",
							actionTypeId: "TRANSFORM_SCALE",
							config: {
								delay: 400,
								easing: "ease",
								duration: 1e3,
								target: {},
								xValue: 1,
								yValue: 1,
								locked: !0,
							},
						},
						{
							id: "a-95-n-47",
							actionTypeId: "STYLE_SIZE",
							config: {
								delay: 400,
								easing: "ease",
								duration: 1e3,
								target: {},
								widthUnit: "PX",
								heightUnit: "AUTO",
								locked: !1,
							},
						},
						{
							id: "a-95-n-48",
							actionTypeId: "STYLE_SIZE",
							config: {
								delay: 400,
								easing: "ease",
								duration: 1e3,
								target: {},
								widthUnit: "PX",
								heightUnit: "AUTO",
								locked: !1,
							},
						},
						{
							id: "a-95-n-49",
							actionTypeId: "STYLE_SIZE",
							config: {
								delay: 400,
								easing: "ease",
								duration: 1e3,
								target: {},
								widthUnit: "AUTO",
								heightUnit: "PX",
								locked: !1,
							},
						},
						{
							id: "a-95-n-50",
							actionTypeId: "STYLE_SIZE",
							config: {
								delay: 400,
								easing: "ease",
								duration: 1e3,
								target: {},
								widthUnit: "PX",
								heightUnit: "AUTO",
								locked: !1,
							},
						},
						{
							id: "a-95-n-51",
							actionTypeId: "TRANSFORM_SCALE",
							config: {
								delay: 400,
								easing: "ease",
								duration: 1e3,
								target: {},
								xValue: 1,
								yValue: 1,
								locked: !0,
							},
						},
						{
							id: "a-95-n-52",
							actionTypeId: "STYLE_SIZE",
							config: {
								delay: 400,
								easing: "ease",
								duration: 1e3,
								target: {},
								widthUnit: "AUTO",
								heightUnit: "PX",
								locked: !1,
							},
						},
						{
							id: "a-95-n-53",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 500,
								easing: "ease",
								duration: 1e3,
								target: {},
								yValue: 0,
								xUnit: "PX",
								yUnit: "px",
								zUnit: "PX",
							},
						},
						{
							id: "a-95-n-54",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 500,
								easing: "ease",
								duration: 1e3,
								target: {},
								value: 1,
								unit: "",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x17d29eac356,
		},
		"a-96": {
			id: "a-96",
			title: "First Section BG",
			continuousParameterGroups: [
				{
					id: "a-96-p",
					type: "SCROLL_PROGRESS",
					parameterLabel: "Scroll",
					continuousActionGroups: [
						{
							keyframe: 0,
							actionItems: [
								{
									id: "a-96-n",
									actionTypeId: "STYLE_BACKGROUND_COLOR",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											id: "673ee4cd088aa593ca5ba409|cf369d5c-2cd2-6560-8692-c3a20be0c2d0",
										},
										globalSwatchId: "--dark-slate-grey",
										rValue: 0,
										bValue: 70,
										gValue: 20,
										aValue: 1,
									},
								},
							],
						},
						{
							keyframe: 22,
							actionItems: [
								{
									id: "a-96-n-4",
									actionTypeId: "STYLE_BACKGROUND_COLOR",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											id: "673ee4cd088aa593ca5ba409|cf369d5c-2cd2-6560-8692-c3a20be0c2d0",
										},
										globalSwatchId: "--dark-slate-grey",
										rValue: 0,
										bValue: 70,
										gValue: 20,
										aValue: 1,
									},
								},
								{
									id: "a-96-n-5",
									actionTypeId: "STYLE_TEXT_COLOR",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											id: "673ee4cd088aa593ca5ba409|ece9f6ca-dc14-050f-e933-f54024f03912",
										},
										globalSwatchId: "--white",
										rValue: 255,
										bValue: 255,
										gValue: 255,
										aValue: 1,
									},
								},
								{
									id: "a-96-n-6",
									actionTypeId: "STYLE_TEXT_COLOR",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											id: "673ee4cd088aa593ca5ba409|fa3ae576-fa91-f3ee-694b-dd9a0a1c30e4",
										},
										globalSwatchId: "--white",
										rValue: 255,
										bValue: 255,
										gValue: 255,
										aValue: 1,
									},
								},
								{
									id: "a-96-n-10",
									actionTypeId: "STYLE_TEXT_COLOR",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											id: "673ee4cd088aa593ca5ba409|215c08a2-81db-9b24-7d70-60b2cbaa8d96",
										},
										globalSwatchId: "--white",
										rValue: 255,
										bValue: 255,
										gValue: 255,
										aValue: 1,
									},
								},
								{
									id: "a-96-n-11",
									actionTypeId: "STYLE_TEXT_COLOR",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											id: "673ee4cd088aa593ca5ba409|c6d6d1cd-f5a0-5758-ea85-bf75e6fcdbf6",
										},
										globalSwatchId: "--white",
										rValue: 255,
										bValue: 255,
										gValue: 255,
										aValue: 1,
									},
								},
							],
						},
						{
							keyframe: 25,
							actionItems: [
								{
									id: "a-96-n-3",
									actionTypeId: "STYLE_BACKGROUND_COLOR",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											id: "673ee4cd088aa593ca5ba409|cf369d5c-2cd2-6560-8692-c3a20be0c2d0",
										},
										globalSwatchId: "--white",
										rValue: 255,
										bValue: 255,
										gValue: 255,
										aValue: 1,
									},
								},
								{
									id: "a-96-n-7",
									actionTypeId: "STYLE_TEXT_COLOR",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											id: "673ee4cd088aa593ca5ba409|ece9f6ca-dc14-050f-e933-f54024f03912",
										},
										globalSwatchId: "--dark-slate-grey",
										rValue: 0,
										bValue: 70,
										gValue: 20,
										aValue: 1,
									},
								},
								{
									id: "a-96-n-8",
									actionTypeId: "STYLE_TEXT_COLOR",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											id: "673ee4cd088aa593ca5ba409|fa3ae576-fa91-f3ee-694b-dd9a0a1c30e4",
										},
										globalSwatchId: "--dark-slate-grey",
										rValue: 0,
										bValue: 70,
										gValue: 20,
										aValue: 1,
									},
								},
								{
									id: "a-96-n-9",
									actionTypeId: "STYLE_TEXT_COLOR",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											id: "673ee4cd088aa593ca5ba409|215c08a2-81db-9b24-7d70-60b2cbaa8d96",
										},
										globalSwatchId: "--dark-slate-grey",
										rValue: 0,
										bValue: 70,
										gValue: 20,
										aValue: 1,
									},
								},
								{
									id: "a-96-n-12",
									actionTypeId: "STYLE_TEXT_COLOR",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											id: "673ee4cd088aa593ca5ba409|c6d6d1cd-f5a0-5758-ea85-bf75e6fcdbf6",
										},
										globalSwatchId: "--dark-slate-grey",
										rValue: 0,
										bValue: 70,
										gValue: 20,
										aValue: 1,
									},
								},
							],
						},
						{
							keyframe: 100,
							actionItems: [
								{
									id: "a-96-n-2",
									actionTypeId: "STYLE_BACKGROUND_COLOR",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											id: "673ee4cd088aa593ca5ba409|cf369d5c-2cd2-6560-8692-c3a20be0c2d0",
										},
										globalSwatchId: "--white",
										rValue: 255,
										bValue: 255,
										gValue: 255,
										aValue: 1,
									},
								},
							],
						},
					],
				},
			],
			createdOn: 0x18fde54cf3e,
		},
		"a-122": {
			id: "a-122",
			title: "SolutionCard-Close",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-122-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "outQuart",
								duration: 800,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".cardexpandingcontent",
									selectorGuids: ["0c7fa04d-5b5e-689d-ba99-0c08f2c99057"],
								},
								yValue: 0,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
						{
							id: "a-122-n-5",
							actionTypeId: "STYLE_SIZE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".card-heading-background",
									selectorGuids: ["3adc79b6-1722-c9a1-25b7-df2acd4a7db0"],
								},
								widthValue: 5,
								widthUnit: "px",
								heightUnit: "PX",
								locked: !1,
							},
						},
						{
							id: "a-122-n-3",
							actionTypeId: "TRANSFORM_SCALE",
							config: {
								delay: 0,
								easing: "outQuart",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".card-image",
									selectorGuids: ["3adc79b6-1722-c9a1-25b7-df2acd4a7da9"],
								},
								xValue: 1,
								yValue: 1,
								locked: !0,
							},
						},
						{
							id: "a-122-n-4",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 800,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".grid-item-overlay",
									selectorGuids: ["3adc79b6-1722-c9a1-25b7-df2acd4a7dac"],
								},
								value: 1,
								unit: "",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !1,
			createdOn: 0x179c4df7693,
		},
		"a-123": {
			id: "a-123",
			title: "SolutionCard-Open",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-123-n-8",
							actionTypeId: "STYLE_SIZE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".card-heading-background",
									selectorGuids: ["3adc79b6-1722-c9a1-25b7-df2acd4a7db0"],
								},
								widthValue: 5,
								widthUnit: "px",
								heightUnit: "PX",
								locked: !1,
							},
						},
						{
							id: "a-123-n-2",
							actionTypeId: "TRANSFORM_SCALE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".card-image",
									selectorGuids: ["3adc79b6-1722-c9a1-25b7-df2acd4a7da9"],
								},
								xValue: 1,
								yValue: 1,
								locked: !0,
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-123-n-4",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "outQuart",
								duration: 800,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".cardexpandingcontent",
									selectorGuids: ["0c7fa04d-5b5e-689d-ba99-0c08f2c99057"],
								},
								yValue: -11.5,
								xUnit: "PX",
								yUnit: "rem",
								zUnit: "PX",
							},
						},
						{
							id: "a-123-n-9",
							actionTypeId: "STYLE_SIZE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".card-heading-background",
									selectorGuids: ["3adc79b6-1722-c9a1-25b7-df2acd4a7db0"],
								},
								widthValue: 100,
								widthUnit: "%",
								heightUnit: "PX",
								locked: !1,
							},
						},
						{
							id: "a-123-n-5",
							actionTypeId: "TRANSFORM_SCALE",
							config: {
								delay: 0,
								easing: "outQuart",
								duration: 1e3,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".card-image",
									selectorGuids: ["3adc79b6-1722-c9a1-25b7-df2acd4a7da9"],
								},
								xValue: 1.1,
								yValue: 1.1,
								locked: !0,
							},
						},
						{
							id: "a-123-n-7",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 1e3,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".grid-item-overlay",
									selectorGuids: ["3adc79b6-1722-c9a1-25b7-df2acd4a7dac"],
								},
								value: 0,
								unit: "",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x179c4debf09,
		},
		"a-105": {
			id: "a-105",
			title: "close notification 2",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-105-n",
							actionTypeId: "GENERAL_DISPLAY",
							config: {
								delay: 100,
								easing: "",
								duration: 0,
								target: {
									useEventTarget: "PARENT",
									selector: ".hero__read.alt",
									selectorGuids: [
										"574ddb9b-9322-e606-13aa-fe87386a6e2f",
										"98aaf208-3aa1-de68-7cd2-469d324d9e71",
									],
								},
								value: "none",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !1,
			createdOn: 0x188fd31afa7,
		},
		"a-106": {
			id: "a-106",
			title: "Lottie 1",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-106-n",
							actionTypeId: "PLUGIN_LOTTIE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									id: "673ee4cd088aa593ca5ba409|9d0cb199-41a3-a95a-8615-44341f6eecec",
								},
								value: 14,
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-106-n-2",
							actionTypeId: "PLUGIN_LOTTIE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									id: "673ee4cd088aa593ca5ba409|9d0cb199-41a3-a95a-8615-44341f6eecec",
								},
								value: 35,
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-106-n-3",
							actionTypeId: "PLUGIN_LOTTIE",
							config: {
								delay: 0,
								easing: "",
								duration: 3e3,
								target: {
									useEventTarget: "CHILDREN",
									id: "673ee4cd088aa593ca5ba409|9d0cb199-41a3-a95a-8615-44341f6eecec",
								},
								value: 100,
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-106-n-4",
							actionTypeId: "PLUGIN_LOTTIE",
							config: {
								delay: 0,
								easing: "",
								duration: 3e3,
								target: {
									useEventTarget: "CHILDREN",
									id: "673ee4cd088aa593ca5ba409|9d0cb199-41a3-a95a-8615-44341f6eecec",
								},
								value: 14.9,
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x18fe33bd490,
		},
		"a-107": {
			id: "a-107",
			title: "Lottie 2",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-107-n",
							actionTypeId: "PLUGIN_LOTTIE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									id: "673ee4cd088aa593ca5ba409|9d0cb199-41a3-a95a-8615-44341f6eecf6",
								},
								value: 19,
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-107-n-2",
							actionTypeId: "PLUGIN_LOTTIE",
							config: {
								delay: 0,
								easing: "",
								duration: 1500,
								target: {
									useEventTarget: "CHILDREN",
									id: "673ee4cd088aa593ca5ba409|9d0cb199-41a3-a95a-8615-44341f6eecf6",
								},
								value: 100,
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !1,
			createdOn: 0x18fe33bd490,
		},
		"a-108": {
			id: "a-108",
			title: "Lottie 3",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-108-n",
							actionTypeId: "PLUGIN_LOTTIE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									id: "673ee4cd088aa593ca5ba409|9d0cb199-41a3-a95a-8615-44341f6eed00",
								},
								value: 0,
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-108-n-2",
							actionTypeId: "PLUGIN_LOTTIE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									id: "673ee4cd088aa593ca5ba409|9d0cb199-41a3-a95a-8615-44341f6eed00",
								},
								value: 35,
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-108-n-3",
							actionTypeId: "PLUGIN_LOTTIE",
							config: {
								delay: 0,
								easing: "",
								duration: 3e3,
								target: {
									id: "673ee4cd088aa593ca5ba409|9d0cb199-41a3-a95a-8615-44341f6eed00",
								},
								value: 86,
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x18fe33bd490,
		},
		"a-109": {
			id: "a-109",
			title: "Lottie 4",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-109-n",
							actionTypeId: "PLUGIN_LOTTIE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									id: "673ee4cd088aa593ca5ba409|9d0cb199-41a3-a95a-8615-44341f6eed0c",
								},
								value: 40,
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-109-n-2",
							actionTypeId: "PLUGIN_LOTTIE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									id: "673ee4cd088aa593ca5ba409|9d0cb199-41a3-a95a-8615-44341f6eed0c",
								},
								value: 19,
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-109-n-3",
							actionTypeId: "PLUGIN_LOTTIE",
							config: {
								delay: 0,
								easing: "",
								duration: 2e3,
								target: {
									useEventTarget: "CHILDREN",
									id: "673ee4cd088aa593ca5ba409|9d0cb199-41a3-a95a-8615-44341f6eed0c",
								},
								value: 100,
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x18fe33bd490,
		},
		"a-111": {
			id: "a-111",
			title: "Inside Page Banner",
			continuousParameterGroups: [
				{
					id: "a-111-p",
					type: "SCROLL_PROGRESS",
					parameterLabel: "Scroll",
					continuousActionGroups: [
						{
							keyframe: 2,
							actionItems: [
								{
									id: "a-111-n",
									actionTypeId: "TRANSFORM_SCALE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											selector: ".inside-page-banner",
											selectorGuids: ["23064222-aa62-9c7f-3ad3-869e1fdc09de"],
										},
										xValue: 1,
										yValue: 1,
										locked: !0,
									},
								},
							],
						},
						{
							keyframe: 16,
							actionItems: [
								{
									id: "a-111-n-2",
									actionTypeId: "TRANSFORM_SCALE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: {
											selector: ".inside-page-banner",
											selectorGuids: ["23064222-aa62-9c7f-3ad3-869e1fdc09de"],
										},
										xValue: 1.1,
										yValue: 1.1,
										locked: !0,
									},
								},
							],
						},
					],
				},
			],
			createdOn: 0x1912c571a80,
		},
		"a-112": {
			id: "a-112",
			title: "(NAVIGATION) Dropdown [open] 2",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-112-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".navigation-dropdown",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad195"],
								},
								yValue: -6,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-112-n-2",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".navigation-dropdown",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad195"],
								},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-112-n-3",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".dropdown-link-1",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad192"],
								},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-112-n-4",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".dropdown-link-1",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad192"],
								},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-112-n-5",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-112-n-6",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-112-n-7",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-112-n-8",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-112-n-9",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-112-n-10",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-112-n-11",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-112-n-12",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-112-n-13",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								xValue: -4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-112-n-14",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								xValue: -4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-112-n-15",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 300,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-112-n-16",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								value: 0,
								unit: "",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-112-n-17",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "easeOut",
								duration: 300,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".navigation-dropdown",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad195"],
								},
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-112-n-18",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 300,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".navigation-dropdown",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad195"],
								},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-112-n-19",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 200,
								easing: "ease",
								duration: 300,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".dropdown-link-1",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad192"],
								},
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-112-n-20",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 200,
								easing: "ease",
								duration: 300,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".dropdown-link-1",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad192"],
								},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-112-n-21",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 300,
								easing: "ease",
								duration: 300,
								target: {},
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-112-n-22",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 300,
								easing: "ease",
								duration: 300,
								target: {},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-112-n-23",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 300,
								easing: "ease",
								duration: 400,
								target: {},
								xValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-112-n-24",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 300,
								easing: "ease",
								duration: 400,
								target: {},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-112-n-25",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 400,
								easing: "ease",
								duration: 300,
								target: {},
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-112-n-26",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 400,
								easing: "ease",
								duration: 300,
								target: {},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-112-n-27",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 400,
								easing: "ease",
								duration: 400,
								target: {},
								xValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-112-n-28",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 400,
								easing: "ease",
								duration: 400,
								target: {},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-112-n-29",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 500,
								easing: "ease",
								duration: 300,
								target: {},
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-112-n-30",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 500,
								easing: "ease",
								duration: 300,
								target: {},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-112-n-31",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 600,
								easing: "ease",
								duration: 300,
								target: {},
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-112-n-32",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 600,
								easing: "ease",
								duration: 300,
								target: {},
								value: 1,
								unit: "",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x16f209c5d5b,
		},
		"a-113": {
			id: "a-113",
			title: "(NAVIGATION) Dropdown [close] 2",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-113-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".navigation-dropdown",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad195"],
								},
								yValue: -6,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-113-n-2",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".navigation-dropdown",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad195"],
								},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-113-n-3",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".dropdown-link-1",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad192"],
								},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-113-n-4",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".dropdown-link-1",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad192"],
								},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-113-n-5",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-113-n-6",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-113-n-7",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-113-n-8",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-113-n-9",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-113-n-10",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-113-n-11",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-113-n-12",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-113-n-13",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 0,
								target: {},
								xValue: -4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-113-n-14",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 0,
								target: {},
								xValue: -4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-113-n-15",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 0,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-113-n-16",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 0,
								target: {},
								value: 0,
								unit: "",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !1,
			createdOn: 0x16f209c5d5b,
		},
		"a-129": {
			id: "a-129",
			title: "Case Study Filters",
			continuousParameterGroups: [
				{
					id: "a-129-p",
					type: "SCROLL_PROGRESS",
					parameterLabel: "Scroll",
					continuousActionGroups: [],
				},
			],
			createdOn: 0x19251681ed7,
		},
		"a-130": {
			id: "a-130",
			title: "(NAVIGATION) Dropdown [open] 3",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-130-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".navigation-dropdown",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad195"],
								},
								yValue: -6,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-130-n-2",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".navigation-dropdown",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad195"],
								},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-130-n-3",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".dropdown-link-1",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad192"],
								},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-130-n-4",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".dropdown-link-1",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad192"],
								},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-130-n-5",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-130-n-6",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-130-n-7",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-130-n-8",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-130-n-9",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-130-n-10",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-130-n-11",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-130-n-12",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-130-n-13",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								xValue: -4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-130-n-14",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								xValue: -4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-130-n-15",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 300,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-130-n-16",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								value: 0,
								unit: "",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-130-n-17",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "easeOut",
								duration: 300,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".navigation-dropdown",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad195"],
								},
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-130-n-18",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 300,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".navigation-dropdown",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad195"],
								},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-130-n-19",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 200,
								easing: "ease",
								duration: 300,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".dropdown-link-1",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad192"],
								},
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-130-n-20",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 200,
								easing: "ease",
								duration: 300,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".dropdown-link-1",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad192"],
								},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-130-n-21",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 300,
								easing: "ease",
								duration: 300,
								target: {},
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-130-n-22",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 300,
								easing: "ease",
								duration: 300,
								target: {},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-130-n-23",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 300,
								easing: "ease",
								duration: 400,
								target: {},
								xValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-130-n-24",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 300,
								easing: "ease",
								duration: 400,
								target: {},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-130-n-25",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 400,
								easing: "ease",
								duration: 300,
								target: {},
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-130-n-26",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 400,
								easing: "ease",
								duration: 300,
								target: {},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-130-n-27",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 400,
								easing: "ease",
								duration: 400,
								target: {},
								xValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-130-n-28",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 400,
								easing: "ease",
								duration: 400,
								target: {},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-130-n-29",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 500,
								easing: "ease",
								duration: 300,
								target: {},
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-130-n-30",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 500,
								easing: "ease",
								duration: 300,
								target: {},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-130-n-31",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 600,
								easing: "ease",
								duration: 300,
								target: {},
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-130-n-32",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 600,
								easing: "ease",
								duration: 300,
								target: {},
								value: 1,
								unit: "",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x16f209c5d5b,
		},
		"a-131": {
			id: "a-131",
			title: "(NAVIGATION) Dropdown [close] 3",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-131-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".navigation-dropdown",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad195"],
								},
								yValue: -6,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-131-n-2",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".navigation-dropdown",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad195"],
								},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-131-n-3",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".dropdown-link-1",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad192"],
								},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-131-n-4",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".dropdown-link-1",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad192"],
								},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-131-n-5",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-131-n-6",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-131-n-7",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-131-n-8",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-131-n-9",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-131-n-10",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-131-n-11",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-131-n-12",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-131-n-13",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 0,
								target: {},
								xValue: -4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-131-n-14",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 0,
								target: {},
								xValue: -4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-131-n-15",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 0,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-131-n-16",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 0,
								target: {},
								value: 0,
								unit: "",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !1,
			createdOn: 0x16f209c5d5b,
		},
		"a-132": {
			id: "a-132",
			title: "d-88-mouse-in",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-132-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: { id: "4b24e683-1a33-92d2-81cf-0b09710c501c" },
								yValue: -10,
								xUnit: "PX",
								yUnit: "px",
								zUnit: "PX",
							},
						},
						{
							id: "a-132-n-2",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: { id: "4b24e683-1a33-92d2-81cf-0b09710c501e" },
								yValue: 10,
								xUnit: "PX",
								yUnit: "px",
								zUnit: "PX",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-132-n-3",
							actionTypeId: "TRANSFORM_ROTATE",
							config: {
								delay: 0,
								easing: "outExpo",
								duration: 500,
								target: { id: "4b24e683-1a33-92d2-81cf-0b09710c501b" },
								zValue: 90,
								xUnit: "DEG",
								yUnit: "DEG",
								zUnit: "DEG",
							},
						},
						{
							id: "a-132-n-4",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "outExpo",
								duration: 500,
								target: {
									id: "6494348412fa763040e72726|6ececcc5-42e9-828f-31ea-1bdc16093b84",
								},
								yValue: 0,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
						{
							id: "a-132-n-5",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 300,
								easing: "outExpo",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".d-88-trigger-line",
									selectorGuids: ["a3ce2746-a8e5-a0cb-d37f-3d19d99e8740"],
								},
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-132-n-6",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 300,
								easing: "outExpo",
								duration: 500,
								target: { id: "4b24e683-1a33-92d2-81cf-0b09710c501e" },
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-132-n-7",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 500,
								easing: "",
								duration: 0,
								target: { id: "4b24e683-1a33-92d2-81cf-0b09710c501d" },
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-132-n-8",
							actionTypeId: "TRANSFORM_ROTATE",
							config: {
								delay: 600,
								easing: "outExpo",
								duration: 500,
								target: { id: "4b24e683-1a33-92d2-81cf-0b09710c501c" },
								zValue: -45,
								xUnit: "DEG",
								yUnit: "DEG",
								zUnit: "DEG",
							},
						},
						{
							id: "a-132-n-9",
							actionTypeId: "TRANSFORM_ROTATE",
							config: {
								delay: 600,
								easing: "outExpo",
								duration: 500,
								target: { id: "4b24e683-1a33-92d2-81cf-0b09710c501e" },
								zValue: 45,
								xUnit: "DEG",
								yUnit: "DEG",
								zUnit: "DEG",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x16978ccadb5,
		},
		"a-133": {
			id: "a-133",
			title: "d-88-mouse-out",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-133-n",
							actionTypeId: "TRANSFORM_ROTATE",
							config: {
								delay: 0,
								easing: "",
								duration: 0,
								target: { id: "4b24e683-1a33-92d2-81cf-0b09710c501b" },
								zValue: 0,
								xUnit: "DEG",
								yUnit: "DEG",
								zUnit: "DEG",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-133-n-2",
							actionTypeId: "TRANSFORM_ROTATE",
							config: {
								delay: 0,
								easing: "outExpo",
								duration: 500,
								target: { id: "4b24e683-1a33-92d2-81cf-0b09710c501c" },
								zValue: 0,
								xUnit: "DEG",
								yUnit: "DEG",
								zUnit: "DEG",
							},
						},
						{
							id: "a-133-n-3",
							actionTypeId: "TRANSFORM_ROTATE",
							config: {
								delay: 0,
								easing: "outExpo",
								duration: 500,
								target: { id: "4b24e683-1a33-92d2-81cf-0b09710c501e" },
								zValue: 0,
								xUnit: "DEG",
								yUnit: "DEG",
								zUnit: "DEG",
							},
						},
						{
							id: "a-133-n-4",
							actionTypeId: "TRANSFORM_ROTATE",
							config: {
								delay: 0,
								easing: "outExpo",
								duration: 0,
								target: { id: "4b24e683-1a33-92d2-81cf-0b09710c501d" },
								zValue: 0,
								xUnit: "DEG",
								yUnit: "DEG",
								zUnit: "DEG",
							},
						},
						{
							id: "a-133-n-5",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "outExpo",
								duration: 1e3,
								target: {
									id: "6494348412fa763040e72726|6ececcc5-42e9-828f-31ea-1bdc16093b84",
								},
								yValue: -100,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
						{
							id: "a-133-n-6",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 300,
								easing: "outExpo",
								duration: 500,
								target: { id: "4b24e683-1a33-92d2-81cf-0b09710c501c" },
								yValue: -10,
								xUnit: "PX",
								yUnit: "px",
								zUnit: "PX",
							},
						},
						{
							id: "a-133-n-7",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 300,
								easing: "outExpo",
								duration: 500,
								target: { id: "4b24e683-1a33-92d2-81cf-0b09710c501e" },
								yValue: 10,
								xUnit: "PX",
								yUnit: "px",
								zUnit: "PX",
							},
						},
						{
							id: "a-133-n-8",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 300,
								easing: "",
								duration: 0,
								target: { id: "4b24e683-1a33-92d2-81cf-0b09710c501d" },
								value: 1,
								unit: "",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !1,
			createdOn: 0x16978ccadb5,
		},
		"a-137": {
			id: "a-137",
			title: "search bar open 4",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-137-n",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									selector: ".searchbox-webflow",
									selectorGuids: ["1855b193-7f44-b6d1-444c-b7906ad4fd17"],
								},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-137-n-2",
							actionTypeId: "GENERAL_DISPLAY",
							config: {
								delay: 0,
								easing: "",
								duration: 0,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".exit-cb-wrapper-webflow",
									selectorGuids: ["b2c44df7-4425-c5bd-4d7f-6539bdfd6ee7"],
								},
								value: "none",
							},
						},
						{
							id: "a-137-n-3",
							actionTypeId: "GENERAL_DISPLAY",
							config: {
								delay: 0,
								easing: "",
								duration: 0,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".search-cb-wrapper-webflow",
									selectorGuids: ["b2c44df7-4425-c5bd-4d7f-6539bdfd6ee5"],
								},
								value: "flex",
							},
						},
						{
							id: "a-137-n-4",
							actionTypeId: "STYLE_SIZE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									selector: ".searchbox-webflow",
									selectorGuids: ["1855b193-7f44-b6d1-444c-b7906ad4fd17"],
								},
								heightValue: 0,
								widthUnit: "PX",
								heightUnit: "px",
								locked: !1,
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-137-n-5",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									selector: ".searchbox-webflow",
									selectorGuids: ["1855b193-7f44-b6d1-444c-b7906ad4fd17"],
								},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-137-n-6",
							actionTypeId: "STYLE_SIZE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									selector: ".searchbox-webflow",
									selectorGuids: ["1855b193-7f44-b6d1-444c-b7906ad4fd17"],
								},
								widthUnit: "PX",
								heightUnit: "AUTO",
								locked: !1,
							},
						},
						{
							id: "a-137-n-7",
							actionTypeId: "GENERAL_DISPLAY",
							config: {
								delay: 0,
								easing: "",
								duration: 0,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".search-cb-wrapper-webflow",
									selectorGuids: ["b2c44df7-4425-c5bd-4d7f-6539bdfd6ee5"],
								},
								value: "none",
							},
						},
						{
							id: "a-137-n-8",
							actionTypeId: "GENERAL_DISPLAY",
							config: {
								delay: 0,
								easing: "",
								duration: 0,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".exit-cb-wrapper-webflow",
									selectorGuids: ["b2c44df7-4425-c5bd-4d7f-6539bdfd6ee7"],
								},
								value: "flex",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x18d8d1aff2c,
		},
		"a-138": {
			id: "a-138",
			title: "search bar close 4",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-138-n",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									selector: ".searchbox-webflow",
									selectorGuids: ["1855b193-7f44-b6d1-444c-b7906ad4fd17"],
								},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-138-n-2",
							actionTypeId: "STYLE_SIZE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									selector: ".searchbox-webflow",
									selectorGuids: ["1855b193-7f44-b6d1-444c-b7906ad4fd17"],
								},
								heightValue: 0,
								widthUnit: "PX",
								heightUnit: "px",
								locked: !1,
							},
						},
						{
							id: "a-138-n-3",
							actionTypeId: "GENERAL_DISPLAY",
							config: {
								delay: 0,
								easing: "",
								duration: 0,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".exit-cb-wrapper-webflow",
									selectorGuids: ["b2c44df7-4425-c5bd-4d7f-6539bdfd6ee7"],
								},
								value: "none",
							},
						},
						{
							id: "a-138-n-4",
							actionTypeId: "GENERAL_DISPLAY",
							config: {
								delay: 0,
								easing: "",
								duration: 0,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".search-cb-wrapper-webflow",
									selectorGuids: ["b2c44df7-4425-c5bd-4d7f-6539bdfd6ee5"],
								},
								value: "flex",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !1,
			createdOn: 0x18d8d1aff2c,
		},
		"a-139": {
			id: "a-139",
			title: "close notification 4",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-139-n",
							actionTypeId: "GENERAL_DISPLAY",
							config: {
								delay: 100,
								easing: "",
								duration: 0,
								target: {
									useEventTarget: "PARENT",
									selector: ".notification-banner",
									selectorGuids: ["42c2f046-071a-1d67-84cf-5fc454b88946"],
								},
								value: "none",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !1,
			createdOn: 0x188fd31afa7,
		},
		"a-140": {
			id: "a-140",
			title: "d-88-mouse-in 2",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-140-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: { id: "6a34980c-1919-88f5-c825-2ae56ffd3b9d" },
								yValue: -10,
								xUnit: "PX",
								yUnit: "px",
								zUnit: "PX",
							},
						},
						{
							id: "a-140-n-2",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: { id: "6a34980c-1919-88f5-c825-2ae56ffd3b9f" },
								yValue: 10,
								xUnit: "PX",
								yUnit: "px",
								zUnit: "PX",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-140-n-3",
							actionTypeId: "TRANSFORM_ROTATE",
							config: {
								delay: 0,
								easing: "outExpo",
								duration: 500,
								target: { id: "6a34980c-1919-88f5-c825-2ae56ffd3b9c" },
								zValue: 90,
								xUnit: "DEG",
								yUnit: "DEG",
								zUnit: "DEG",
							},
						},
						{
							id: "a-140-n-4",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "outExpo",
								duration: 500,
								target: {
									id: "673ee4cd088aa593ca5ba449|6ececcc5-42e9-828f-31ea-1bdc16093b84",
								},
								yValue: 0,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
						{
							id: "a-140-n-5",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 300,
								easing: "outExpo",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".d-88-trigger-line",
									selectorGuids: ["a3ce2746-a8e5-a0cb-d37f-3d19d99e8740"],
								},
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-140-n-6",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 300,
								easing: "outExpo",
								duration: 500,
								target: { id: "6a34980c-1919-88f5-c825-2ae56ffd3b9f" },
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-140-n-7",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 500,
								easing: "",
								duration: 0,
								target: { id: "6a34980c-1919-88f5-c825-2ae56ffd3b9e" },
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-140-n-8",
							actionTypeId: "TRANSFORM_ROTATE",
							config: {
								delay: 600,
								easing: "outExpo",
								duration: 500,
								target: { id: "6a34980c-1919-88f5-c825-2ae56ffd3b9d" },
								zValue: -45,
								xUnit: "DEG",
								yUnit: "DEG",
								zUnit: "DEG",
							},
						},
						{
							id: "a-140-n-9",
							actionTypeId: "TRANSFORM_ROTATE",
							config: {
								delay: 600,
								easing: "outExpo",
								duration: 500,
								target: { id: "6a34980c-1919-88f5-c825-2ae56ffd3b9f" },
								zValue: 45,
								xUnit: "DEG",
								yUnit: "DEG",
								zUnit: "DEG",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x16978ccadb5,
		},
		"a-141": {
			id: "a-141",
			title: "d-88-mouse-out 2",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-141-n",
							actionTypeId: "TRANSFORM_ROTATE",
							config: {
								delay: 0,
								easing: "",
								duration: 0,
								target: { id: "6a34980c-1919-88f5-c825-2ae56ffd3b9c" },
								zValue: 0,
								xUnit: "DEG",
								yUnit: "DEG",
								zUnit: "DEG",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-141-n-2",
							actionTypeId: "TRANSFORM_ROTATE",
							config: {
								delay: 0,
								easing: "outExpo",
								duration: 500,
								target: { id: "6a34980c-1919-88f5-c825-2ae56ffd3b9d" },
								zValue: 0,
								xUnit: "DEG",
								yUnit: "DEG",
								zUnit: "DEG",
							},
						},
						{
							id: "a-141-n-3",
							actionTypeId: "TRANSFORM_ROTATE",
							config: {
								delay: 0,
								easing: "outExpo",
								duration: 500,
								target: { id: "6a34980c-1919-88f5-c825-2ae56ffd3b9f" },
								zValue: 0,
								xUnit: "DEG",
								yUnit: "DEG",
								zUnit: "DEG",
							},
						},
						{
							id: "a-141-n-4",
							actionTypeId: "TRANSFORM_ROTATE",
							config: {
								delay: 0,
								easing: "outExpo",
								duration: 0,
								target: { id: "6a34980c-1919-88f5-c825-2ae56ffd3b9e" },
								zValue: 0,
								xUnit: "DEG",
								yUnit: "DEG",
								zUnit: "DEG",
							},
						},
						{
							id: "a-141-n-5",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "outExpo",
								duration: 1e3,
								target: {
									id: "673ee4cd088aa593ca5ba449|6ececcc5-42e9-828f-31ea-1bdc16093b84",
								},
								yValue: -100,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
						{
							id: "a-141-n-6",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 300,
								easing: "outExpo",
								duration: 500,
								target: { id: "6a34980c-1919-88f5-c825-2ae56ffd3b9d" },
								yValue: -10,
								xUnit: "PX",
								yUnit: "px",
								zUnit: "PX",
							},
						},
						{
							id: "a-141-n-7",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 300,
								easing: "outExpo",
								duration: 500,
								target: { id: "6a34980c-1919-88f5-c825-2ae56ffd3b9f" },
								yValue: 10,
								xUnit: "PX",
								yUnit: "px",
								zUnit: "PX",
							},
						},
						{
							id: "a-141-n-8",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 300,
								easing: "",
								duration: 0,
								target: { id: "6a34980c-1919-88f5-c825-2ae56ffd3b9e" },
								value: 1,
								unit: "",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !1,
			createdOn: 0x16978ccadb5,
		},
		"a-142": {
			id: "a-142",
			title: "(NAVIGATION) Dropdown [open] 4",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-142-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".navigation-dropdown",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad195"],
								},
								yValue: -6,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-142-n-2",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".navigation-dropdown",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad195"],
								},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-142-n-3",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".dropdown-link-1",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad192"],
								},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-142-n-4",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".dropdown-link-1",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad192"],
								},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-142-n-5",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-142-n-6",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-142-n-7",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-142-n-8",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-142-n-9",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-142-n-10",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-142-n-11",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-142-n-12",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-142-n-13",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								xValue: -4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-142-n-14",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								xValue: -4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-142-n-15",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 300,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-142-n-16",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {},
								value: 0,
								unit: "",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-142-n-17",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "easeOut",
								duration: 300,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".navigation-dropdown",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad195"],
								},
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-142-n-18",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 300,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".navigation-dropdown",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad195"],
								},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-142-n-19",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 200,
								easing: "ease",
								duration: 300,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".dropdown-link-1",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad192"],
								},
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-142-n-20",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 200,
								easing: "ease",
								duration: 300,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".dropdown-link-1",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad192"],
								},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-142-n-21",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 300,
								easing: "ease",
								duration: 300,
								target: {},
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-142-n-22",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 300,
								easing: "ease",
								duration: 300,
								target: {},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-142-n-23",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 300,
								easing: "ease",
								duration: 400,
								target: {},
								xValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-142-n-24",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 300,
								easing: "ease",
								duration: 400,
								target: {},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-142-n-25",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 400,
								easing: "ease",
								duration: 300,
								target: {},
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-142-n-26",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 400,
								easing: "ease",
								duration: 300,
								target: {},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-142-n-27",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 400,
								easing: "ease",
								duration: 400,
								target: {},
								xValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-142-n-28",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 400,
								easing: "ease",
								duration: 400,
								target: {},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-142-n-29",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 500,
								easing: "ease",
								duration: 300,
								target: {},
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-142-n-30",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 500,
								easing: "ease",
								duration: 300,
								target: {},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-142-n-31",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 600,
								easing: "ease",
								duration: 300,
								target: {},
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-142-n-32",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 600,
								easing: "ease",
								duration: 300,
								target: {},
								value: 1,
								unit: "",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x16f209c5d5b,
		},
		"a-143": {
			id: "a-143",
			title: "(NAVIGATION) Dropdown [close] 4",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-143-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".navigation-dropdown",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad195"],
								},
								yValue: -6,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-143-n-2",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".navigation-dropdown",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad195"],
								},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-143-n-3",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".dropdown-link-1",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad192"],
								},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-143-n-4",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".dropdown-link-1",
									selectorGuids: ["56ef4d2e-da64-7c74-2437-ce0bae6ad192"],
								},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-143-n-5",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-143-n-6",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-143-n-7",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-143-n-8",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-143-n-9",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-143-n-10",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-143-n-11",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								yValue: 4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-143-n-12",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 200,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-143-n-13",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 0,
								target: {},
								xValue: -4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-143-n-14",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "ease",
								duration: 0,
								target: {},
								xValue: -4,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							id: "a-143-n-15",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 0,
								target: {},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-143-n-16",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "ease",
								duration: 0,
								target: {},
								value: 0,
								unit: "",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !1,
			createdOn: 0x16f209c5d5b,
		},
		"a-145": {
			id: "a-145",
			title: "Heading reveal 4",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-145-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: [0.86, 0, 0.07, 1],
								duration: 2500,
								target: {
									useEventTarget: !0,
									id: "673ee4cd088aa593ca5ba409|77f4271b-125b-a1f8-7371-6ff490361a55",
								},
								yValue: 120,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
						{
							id: "a-145-n-2",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: !0,
									id: "673ee4cd088aa593ca5ba409|77f4271b-125b-a1f8-7371-6ff490361a55",
								},
								value: 0,
								unit: "",
							},
						},
						{
							id: "a-145-n-3",
							actionTypeId: "TRANSFORM_SKEW",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									useEventTarget: !0,
									id: "673ee4cd088aa593ca5ba409|77f4271b-125b-a1f8-7371-6ff490361a55",
								},
								yValue: 5,
								xUnit: "DEG",
								yUnit: "deg",
								zUnit: "DEG",
							},
						},
						{
							id: "a-145-n-4",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: [0.86, 0, 0.07, 1],
								duration: 2500,
								target: {
									selector: ".hero_h1-wrapper",
									selectorGuids: ["438ec268-248c-86fb-295a-228f74403bc0"],
								},
								yValue: 120,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
						{
							id: "a-145-n-5",
							actionTypeId: "TRANSFORM_SKEW",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									selector: ".hero_h1-wrapper",
									selectorGuids: ["438ec268-248c-86fb-295a-228f74403bc0"],
								},
								yValue: 5,
								xUnit: "DEG",
								yUnit: "deg",
								zUnit: "DEG",
							},
						},
						{
							id: "a-145-n-6",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "",
								duration: 500,
								target: {
									selector: ".hero_h1-wrapper",
									selectorGuids: ["438ec268-248c-86fb-295a-228f74403bc0"],
								},
								value: 0,
								unit: "",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-145-n-7",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 500,
								easing: [0.23, 1, 0.32, 1],
								duration: 2500,
								target: {
									useEventTarget: !0,
									id: "673ee4cd088aa593ca5ba409|77f4271b-125b-a1f8-7371-6ff490361a55",
								},
								yValue: 0,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
						{
							id: "a-145-n-8",
							actionTypeId: "TRANSFORM_SKEW",
							config: {
								delay: 500,
								easing: [0.23, 1, 0.32, 1],
								duration: 2500,
								target: {
									useEventTarget: !0,
									id: "673ee4cd088aa593ca5ba409|77f4271b-125b-a1f8-7371-6ff490361a55",
								},
								yValue: 0,
								xUnit: "DEG",
								yUnit: "deg",
								zUnit: "DEG",
							},
						},
						{
							id: "a-145-n-9",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 500,
								easing: [0.25, 0.25, 0.75, 0.75],
								duration: 2500,
								target: {
									useEventTarget: !0,
									id: "673ee4cd088aa593ca5ba409|77f4271b-125b-a1f8-7371-6ff490361a55",
								},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-145-n-10",
							actionTypeId: "TRANSFORM_SKEW",
							config: {
								delay: 500,
								easing: [0.23, 1, 0.32, 1],
								duration: 2500,
								target: {
									selector: ".hero_h1-wrapper",
									selectorGuids: ["438ec268-248c-86fb-295a-228f74403bc0"],
								},
								yValue: 0,
								xUnit: "DEG",
								yUnit: "deg",
								zUnit: "DEG",
							},
						},
						{
							id: "a-145-n-11",
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 500,
								easing: [0.25, 0.25, 0.75, 0.75],
								duration: 2500,
								target: {
									selector: ".hero_h1-wrapper",
									selectorGuids: ["438ec268-248c-86fb-295a-228f74403bc0"],
								},
								value: 1,
								unit: "",
							},
						},
						{
							id: "a-145-n-12",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 500,
								easing: [0.23, 1, 0.32, 1],
								duration: 2500,
								target: {
									selector: ".hero_h1-wrapper",
									selectorGuids: ["438ec268-248c-86fb-295a-228f74403bc0"],
								},
								yValue: 0,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x186816cf83d,
		},
		"a-146": {
			id: "a-146",
			title: "hero_push-text landscape Mobile",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-146-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "inOutCubic",
								duration: 500,
								target: {
									id: "673ee4cd088aa593ca5ba409|77f4271b-125b-a1f8-7371-6ff490361a58",
								},
								yValue: 0,
								xUnit: "PX",
								yUnit: "px",
								zUnit: "PX",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-146-n-2",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 2e3,
								easing: "inOutCubic",
								duration: 500,
								target: {
									id: "673ee4cd088aa593ca5ba409|77f4271b-125b-a1f8-7371-6ff490361a58",
								},
								yValue: -105,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-146-n-3",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 1500,
								easing: "inOutCubic",
								duration: 500,
								target: {
									id: "673ee4cd088aa593ca5ba409|77f4271b-125b-a1f8-7371-6ff490361a58",
								},
								yValue: -215,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-146-n-4",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 1500,
								easing: "inOutCubic",
								duration: 500,
								target: {
									id: "673ee4cd088aa593ca5ba409|77f4271b-125b-a1f8-7371-6ff490361a58",
								},
								yValue: 0,
								xUnit: "PX",
								yUnit: "px",
								zUnit: "PX",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x18e584d44b9,
		},
		"a-144": {
			id: "a-144",
			title: "hero_push-text hero Desktop-Tablet",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-144-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "inOutCubic",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".hero_h1-wrapper",
									selectorGuids: ["438ec268-248c-86fb-295a-228f74403bc0"],
								},
								yValue: 0,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-144-n-2",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 2e3,
								easing: "inOutCubic",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".hero_h1-wrapper",
									selectorGuids: ["438ec268-248c-86fb-295a-228f74403bc0"],
								},
								yValue: -105,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-144-n-3",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 1500,
								easing: "inOutCubic",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".hero_h1-wrapper",
									selectorGuids: ["438ec268-248c-86fb-295a-228f74403bc0"],
								},
								yValue: -215,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-144-n-4",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 1500,
								easing: "inOutCubic",
								duration: 500,
								target: {
									useEventTarget: "CHILDREN",
									selector: ".hero_h1-wrapper",
									selectorGuids: ["438ec268-248c-86fb-295a-228f74403bc0"],
								},
								yValue: 0,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x18e584d44b9,
		},
		"a-147": {
			id: "a-147",
			title: "hero_push-text Mobile",
			actionItemGroups: [
				{
					actionItems: [
						{
							id: "a-147-n",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "inOutCubic",
								duration: 500,
								target: {
									id: "673ee4cd088aa593ca5ba409|77f4271b-125b-a1f8-7371-6ff490361a58",
								},
								yValue: 0,
								xUnit: "PX",
								yUnit: "px",
								zUnit: "PX",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-147-n-2",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 2e3,
								easing: "inOutCubic",
								duration: 500,
								target: {
									id: "673ee4cd088aa593ca5ba409|77f4271b-125b-a1f8-7371-6ff490361a58",
								},
								yValue: -115,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-147-n-3",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 1500,
								easing: "inOutCubic",
								duration: 500,
								target: {
									id: "673ee4cd088aa593ca5ba409|77f4271b-125b-a1f8-7371-6ff490361a58",
								},
								yValue: -240,
								xUnit: "PX",
								yUnit: "%",
								zUnit: "PX",
							},
						},
					],
				},
				{
					actionItems: [
						{
							id: "a-147-n-4",
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 1500,
								easing: "inOutCubic",
								duration: 500,
								target: {
									id: "673ee4cd088aa593ca5ba409|77f4271b-125b-a1f8-7371-6ff490361a58",
								},
								yValue: 0,
								xUnit: "PX",
								yUnit: "px",
								zUnit: "PX",
							},
						},
					],
				},
			],
			useFirstGroupAsInitialState: !0,
			createdOn: 0x18e584d44b9,
		},
		"a-148": {
			id: "a-148",
			title: "nav bar mobile",
			continuousParameterGroups: [
				{
					id: "a-148-p",
					type: "SCROLL_PROGRESS",
					parameterLabel: "Scroll",
					continuousActionGroups: [
						{
							keyframe: 0,
							actionItems: [
								{
									id: "a-148-n",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: { id: "49074ee8-d6e9-617e-8005-9df85d8ca5dd" },
										yValue: 0,
										xUnit: "PX",
										yUnit: "%",
										zUnit: "PX",
									},
								},
								{
									id: "a-148-n-2",
									actionTypeId: "STYLE_OPACITY",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: { id: "49074ee8-d6e9-617e-8005-9df85d8ca5dd" },
										value: 0,
										unit: "",
									},
								},
								{
									id: "a-148-n-4",
									actionTypeId: "STYLE_OPACITY",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: { id: "d17ecba8-4c00-b14a-ae3f-2c68d7ae0603" },
										value: 1,
										unit: "",
									},
								},
								{
									id: "a-148-n-5",
									actionTypeId: "STYLE_OPACITY",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: { id: "92eaa664-8693-4b83-e6f1-7ea8166418c4" },
										value: 0,
										unit: "",
									},
								},
							],
						},
						{
							keyframe: 4,
							actionItems: [
								{
									id: "a-148-n-7",
									actionTypeId: "TRANSFORM_MOVE",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: { id: "49074ee8-d6e9-617e-8005-9df85d8ca5dd" },
										yValue: 100,
										xUnit: "PX",
										yUnit: "%",
										zUnit: "PX",
									},
								},
								{
									id: "a-148-n-8",
									actionTypeId: "STYLE_OPACITY",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: { id: "49074ee8-d6e9-617e-8005-9df85d8ca5dd" },
										value: 1,
										unit: "",
									},
								},
								{
									id: "a-148-n-10",
									actionTypeId: "STYLE_OPACITY",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: { id: "d17ecba8-4c00-b14a-ae3f-2c68d7ae0603" },
										value: 0,
										unit: "",
									},
								},
								{
									id: "a-148-n-11",
									actionTypeId: "STYLE_OPACITY",
									config: {
										delay: 0,
										easing: "",
										duration: 500,
										target: { id: "92eaa664-8693-4b83-e6f1-7ea8166418c4" },
										value: 1,
										unit: "",
									},
								},
							],
						},
					],
				},
			],
			createdOn: 0x18d8d15ef1d,
		},
		slideInBottom: {
			id: "slideInBottom",
			useFirstGroupAsInitialState: !0,
			actionItemGroups: [
				{
					actionItems: [
						{
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								duration: 0,
								target: {
									id: "N/A",
									appliesTo: "TRIGGER_ELEMENT",
									useEventTarget: !0,
								},
								value: 0,
							},
						},
					],
				},
				{
					actionItems: [
						{
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								duration: 0,
								target: {
									id: "N/A",
									appliesTo: "TRIGGER_ELEMENT",
									useEventTarget: !0,
								},
								xValue: 0,
								yValue: 100,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
					],
				},
				{
					actionItems: [
						{
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "outQuart",
								duration: 1e3,
								target: {
									id: "N/A",
									appliesTo: "TRIGGER_ELEMENT",
									useEventTarget: !0,
								},
								xValue: 0,
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
						{
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "outQuart",
								duration: 1e3,
								target: {
									id: "N/A",
									appliesTo: "TRIGGER_ELEMENT",
									useEventTarget: !0,
								},
								value: 1,
							},
						},
					],
				},
			],
		},
		slideInTop: {
			id: "slideInTop",
			useFirstGroupAsInitialState: !0,
			actionItemGroups: [
				{
					actionItems: [
						{
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								duration: 0,
								target: {
									id: "N/A",
									appliesTo: "TRIGGER_ELEMENT",
									useEventTarget: !0,
								},
								value: 0,
							},
						},
					],
				},
				{
					actionItems: [
						{
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								duration: 0,
								target: {
									id: "N/A",
									appliesTo: "TRIGGER_ELEMENT",
									useEventTarget: !0,
								},
								xValue: 0,
								yValue: -100,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
					],
				},
				{
					actionItems: [
						{
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "outQuart",
								duration: 1e3,
								target: {
									id: "N/A",
									appliesTo: "TRIGGER_ELEMENT",
									useEventTarget: !0,
								},
								value: 1,
							},
						},
						{
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "outQuart",
								duration: 1e3,
								target: {
									id: "N/A",
									appliesTo: "TRIGGER_ELEMENT",
									useEventTarget: !0,
								},
								xValue: 0,
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
					],
				},
			],
		},
		fadeIn: {
			id: "fadeIn",
			useFirstGroupAsInitialState: !0,
			actionItemGroups: [
				{
					actionItems: [
						{
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								duration: 0,
								target: {
									id: "N/A",
									appliesTo: "TRIGGER_ELEMENT",
									useEventTarget: !0,
								},
								value: 0,
							},
						},
					],
				},
				{
					actionItems: [
						{
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "outQuart",
								duration: 1e3,
								target: {
									id: "N/A",
									appliesTo: "TRIGGER_ELEMENT",
									useEventTarget: !0,
								},
								value: 1,
							},
						},
					],
				},
			],
		},
		growIn: {
			id: "growIn",
			useFirstGroupAsInitialState: !0,
			actionItemGroups: [
				{
					actionItems: [
						{
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								duration: 0,
								target: {
									id: "N/A",
									appliesTo: "TRIGGER_ELEMENT",
									useEventTarget: !0,
								},
								value: 0,
							},
						},
					],
				},
				{
					actionItems: [
						{
							actionTypeId: "TRANSFORM_SCALE",
							config: {
								delay: 0,
								duration: 0,
								target: {
									id: "N/A",
									appliesTo: "TRIGGER_ELEMENT",
									useEventTarget: !0,
								},
								xValue: 0.7500000000000001,
								yValue: 0.7500000000000001,
							},
						},
					],
				},
				{
					actionItems: [
						{
							actionTypeId: "TRANSFORM_SCALE",
							config: {
								delay: 0,
								easing: "outQuart",
								duration: 1e3,
								target: {
									id: "N/A",
									appliesTo: "TRIGGER_ELEMENT",
									useEventTarget: !0,
								},
								xValue: 1,
								yValue: 1,
							},
						},
						{
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "outQuart",
								duration: 1e3,
								target: {
									id: "N/A",
									appliesTo: "TRIGGER_ELEMENT",
									useEventTarget: !0,
								},
								value: 1,
							},
						},
					],
				},
			],
		},
		slideInLeft: {
			id: "slideInLeft",
			useFirstGroupAsInitialState: !0,
			actionItemGroups: [
				{
					actionItems: [
						{
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								duration: 0,
								target: {
									id: "N/A",
									appliesTo: "TRIGGER_ELEMENT",
									useEventTarget: !0,
								},
								value: 0,
							},
						},
					],
				},
				{
					actionItems: [
						{
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								duration: 0,
								target: {
									id: "N/A",
									appliesTo: "TRIGGER_ELEMENT",
									useEventTarget: !0,
								},
								xValue: -100,
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
					],
				},
				{
					actionItems: [
						{
							actionTypeId: "STYLE_OPACITY",
							config: {
								delay: 0,
								easing: "outQuart",
								duration: 1e3,
								target: {
									id: "N/A",
									appliesTo: "TRIGGER_ELEMENT",
									useEventTarget: !0,
								},
								value: 1,
							},
						},
						{
							actionTypeId: "TRANSFORM_MOVE",
							config: {
								delay: 0,
								easing: "outQuart",
								duration: 1e3,
								target: {
									id: "N/A",
									appliesTo: "TRIGGER_ELEMENT",
									useEventTarget: !0,
								},
								xValue: 0,
								yValue: 0,
								xUnit: "PX",
								yUnit: "PX",
								zUnit: "PX",
							},
						},
					],
				},
			],
		},
		pluginLottie: {
			id: "pluginLottie",
			actionItemGroups: [
				{
					actionItems: [
						{
							actionTypeId: "PLUGIN_LOTTIE",
							config: {
								delay: 0,
								easing: "",
								duration: 0,
								target: {
									id: "N/A",
									appliesTo: "TRIGGER_ELEMENT",
									useEventTarget: !0,
								},
								value: 0,
							},
						},
					],
				},
				{
					actionItems: [
						{
							actionTypeId: "PLUGIN_LOTTIE",
							config: {
								delay: 0,
								easing: "",
								duration: "auto",
								target: {
									id: "N/A",
									appliesTo: "TRIGGER_ELEMENT",
									useEventTarget: !0,
								},
								value: 100,
							},
						},
					],
				},
			],
		},
	},
	site: {
		mediaQueries: [
			{ key: "main", min: 992, max: 1e4 },
			{ key: "medium", min: 768, max: 991 },
			{ key: "small", min: 480, max: 767 },
			{ key: "tiny", min: 0, max: 479 },
		],
	},
});
/* eslint-enable */
