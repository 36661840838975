jQuery(document).ready(function($) {
	/* Mobile Menu */
	if (window.innerWidth < 992) {
		const menu = new MmenuLight(
			document.querySelector( ".mobile-navigation-menu" )
		);

		const navigator = menu.navigation({
			slidingSubmenus: true
		});

		const drawer = menu.offcanvas({
			position: "right"
		});

		$(".mobile-menu-button").click(function() {
			if ($('.mm-ocd').hasClass("mm-ocd--open")) {
				drawer.close();
			}
			else {
				drawer.open();
			}

			calculateMobileMenuMargin();
		});

		$(window).on("load resize", function() {
			calculateMobileMenuMargin();
		});
	}

	/* Accessible menu */
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".has-children").click(function(event) {
			if (!$(this).parents(".has-children").hasClass("open")) {
				$(".dropdown").removeClass("open");
				event.preventDefault();
				$(this).parent(".has-children").addClass("open");
			}
		});
	}
	else {
		$(".dropdown").hover(
			function() {
				$(this).addClass("open");
			},
			function() {
				$(this).removeClass("open");
			}
		);
	}

	/* Header Alert */
	$(".close-header-alert").click(function() {
		$(".alert-section").hide();
		setCookie("headerAlert", "isShown");
	});

	function setCookie(cname, cvalue) {
		document.cookie = cname + "=" + cvalue + ";path=/";
	}

	if (document.cookie.indexOf("headerAlert=") == -1) {
		$(".alert-section").show();
	}

	// When the window loads, note the scroll position and add and remove classes accordingly
	$(window).scroll(function() {
		if ($(this).scrollTop() > 200) {
			$(".header").addClass("flexy-header");
		}
		else {
			$(".header").removeClass("flexy-header");
		}
	});

	// Use Alt as Caption
	$(".caption").wrap('<div class="caption-wrap"/>');
	$(".caption").each(function() {
		$(this).after('<p class="image-caption">' + $(this).attr("alt") + "</p>");
	});

	// Toggle mini drop-down menu
	$(".mini-menu-dropdown").click(function() {
		$(".mini-menu-dropdown-list").slideToggle();
		$(this).toggleClass("active");
	});

	// Search Open
	$(".search-icon-show").on("click", function() {
		$(".search-form-wrapper").slideDown();
		$(".search-icon-show").hide();
		$(".search-icon-hide").show();
		$(".search-input-3").focus();
	});

	$(".search-icon-hide").on("click", function() {
		$(".search-form-wrapper").slideUp();
		$(".search-icon-show").show();
		$(".search-icon-hide").hide();
	});

	$(".live-chat-button").click(function() {
		var url =
			"https://athena.sitevizgpt.com/chatbot-iframe/7L9ud8iZK8ie0j-0E4TJ6";
		window.open(
			url,
			"_blank",
			"menubar=0,location=0,scrollbars=auto,resizable=1,status=0,width=500,height=700"
		);
	});

	function calculateMobileMenuMargin() {
		$(".mm-ocd").css("top", $(".header").height());
	}

	/* webflow */
	try {
		Typekit.load();
	}
	catch (e) {}
});
